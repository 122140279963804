import React, { useContext, useMemo } from 'react';
import {
  createSearchClient,
  SearchApi,
  TokenCreator,
  useAuth,
} from '@amzn/dots-core-ui';
import {
  createSchedulingClient,
  createTestManagementClient,
  createLabDashboardClient,
  SchedulingApi,
  TestManagementApi,
  LabDashboardApi,
} from './clients';

export type ClientContext = {
  search: SearchApi;
  searchApiUrl: string;
  schedulingApiUrl: string;
  testManagementApiUrl: string;
  labDashboardApiUrl: string;
} & SchedulingApi &
  TestManagementApi &
  LabDashboardApi;

export type ClientProviderProps = {
  children: React.ReactNode;
  createToken?: TokenCreator;
  searchApiUrl: string;
  schedulingApiUrl: string;
  testManagementApiUrl: string;
  labDashboardApiUrl: string;
};

const ClientContext = React.createContext<ClientContext | undefined>(undefined);

export const ClientProvider = ({
  children,
  createToken,
  searchApiUrl,
  schedulingApiUrl,
  testManagementApiUrl,
  labDashboardApiUrl,
}: ClientProviderProps): JSX.Element => {
  let ct = createToken;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ct = useAuth().createToken;
  } catch (error) {
    // ignored
  }
  if (ct === undefined) {
    throw new Error('no Token Creator provided');
  }
  const schedulingClient = createSchedulingClient(
    schedulingApiUrl,
    createToken ?? ct
  );
  const testManagementClient = createTestManagementClient(
    testManagementApiUrl,
    createToken ?? ct
  );
  const labDashboardClient = createLabDashboardClient(
    labDashboardApiUrl,
    createToken ?? ct
  );

  const search = createSearchClient(searchApiUrl, createToken ?? ct);

  const value = useMemo(
    () => ({
      ...schedulingClient,
      ...testManagementClient,
      ...labDashboardClient,
      search,
      searchApiUrl,
      schedulingApiUrl,
      testManagementApiUrl,
      labDashboardApiUrl,
    }),
    [
      schedulingApiUrl,
      schedulingClient,
      search,
      searchApiUrl,
      testManagementApiUrl,
      testManagementClient,
      labDashboardApiUrl,
      labDashboardClient,
    ]
  );
  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = (): ClientContext => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
};
