import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToaster } from '@amzn/dots-core-ui';
import { DeviceMutation, useUpdate, useClient, useGet } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import { DeviceForm } from './components';
import { DeviceDetailUri, DevicesUri, RootUri } from '$/features/routes';

export const DeviceEditPage = (): JSX.Element => {
  const id = useIdParam();
  const history = useHistory();
  const { openToast } = useToaster();
  const { devices } = useClient();
  const {
    data: device,
    error,
    isError,
    isLoading,
  } = useGet(devices.resourceKey, devices.get, id);
  const { mutateAsync } = useUpdate(devices.resourceKey, devices.update, false);
  const onSubmit = useCallback(
    async (item: Partial<DeviceMutation>): Promise<void> => {
      await mutateAsync({ id, item });
      openToast({
        title: 'Success!',
        message: `${device?.dsn} has been updated.`,
        type: 'success',
      });
      history.push(DeviceDetailUri(id));
    },
    [mutateAsync, id, openToast, device, history]
  );
  return (
    <CorePage
      title="Edit"
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Devices', href: DevicesUri },
        { title: device?.dsn ?? '', href: DeviceDetailUri(id) },
      ]}
      header={<CorePageTitle title={device ? `Edit ${device?.dsn}` : ''} />}
    >
      <DeviceForm
        device={device}
        onCancel={() => history.push(DeviceDetailUri(id))}
        onSubmit={onSubmit}
      />
    </CorePage>
  );
};
