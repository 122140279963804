import React from 'react';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import { useClient, useGetAll } from '$/api';

type LabHostsWidgetProps = {
  labId: number;
};

export const LabHostsWidget = ({ labId }: LabHostsWidgetProps): JSX.Element => {
  const { hosts } = useClient();
  const { data, isLoading } = useGetAll(hosts.resourceKey, hosts.getAll, {
    labId,
  });
  return (
    <Column spacing="small">
      <Heading level={3}>Host Widget</Heading>
      {/* data.forEach( hostsResults => )
        Object.keys(payload).forEach((key) => {
        const value =
          (isObject(payload[key]) || Array.isArray(payload[key])) &&
          !(payload[key] instanceof File)
            ? JSON.stringify(payload[key])
            : payload[key];
        formData.append(key, value);
      });
      */}

      {!isLoading && console.log('hosts:', data)}

      {/*
      <CoreTable
        fixHeaderRows
        isLoading={isLoading && !data}
        columnDefinitions={HostColumnDefinitions}
        getItemId={(item: Host) => item.id}
        item={data}
      />
  */}
    </Column>
  );
};
