import React from 'react';
import Column from '@amzn/meridian/column';
import RadioButton from '@amzn/meridian/radio-button';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import {
  CoreInput,
  CoreTextarea,
  CoreFormFooter,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import Alert from '@amzn/meridian/alert';
import { DevicePool, useClient } from '$/api';
import { GroupSelect } from '$/components';

export type PoolFormProps = {
  pool?: DevicePool;
  onSubmit: (pool: Partial<DevicePool>) => Promise<void> | void;
  onCancel: () => void;
};

export const PoolForm = ({
  pool,
  onSubmit,
  onCancel,
}: PoolFormProps): JSX.Element => {
  const { devicePools } = useClient();
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    name: {
      initialValue: pool?.name ?? '',
      validate: async (value): Promise<string> => {
        if (isNullOrWhitespace(value)) {
          return 'Name is required';
        }
        if (value === pool?.name) {
          return '';
        }
        const result = await devicePools.getAll({ name: value });
        if (result.count > 0) {
          return `${value} is already in use`;
        }
        return '';
      },
    },
    description: { initialValue: pool?.description ?? '' },
    ownerGroup: {
      initialValue: pool?.ownerGroup ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value) ? 'Owner is required' : '',
    },
    schedulingMode: { initialValue: pool?.schedulingMode ?? 'on-demand' },
    taskConfig: { initialValue: pool?.taskConfig ?? '' },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        {!pool && (
          <CoreInput
            label="Name"
            value={values.name}
            onChange={handlers.name}
            errorMessage={errors.name}
            validationState={states.name}
          />
        )}
        <Column spacing="small">
          <Text>Scheduling Mode:</Text>
          <Row>
            <RadioButton
              name="schedulingMode"
              onChange={handlers.schedulingMode}
              checked={values.schedulingMode === 'on-demand'}
              value="on-demand"
            >
              On-Demand
            </RadioButton>
            <RadioButton
              name="schedulingMode"
              onChange={handlers.schedulingMode}
              checked={values.schedulingMode === 'collated'}
              value="collated"
            >
              Collated
            </RadioButton>
            <RadioButton
              name="schedulingMode"
              onChange={handlers.schedulingMode}
              checked={values.schedulingMode === 'parallel'}
              value="parallel"
            >
              Parallel
            </RadioButton>
          </Row>
        </Column>
        <CoreTextarea
          label="Description"
          value={values.description}
          onChange={handlers.description}
          errorMessage={errors.description}
          validationState={states.description}
        />
        <GroupSelect
          value={values.ownerGroup}
          onChange={handlers.ownerGroup}
          errorMessage={errors.ownerGroup}
          validationState={states.ownerGroup}
        />
        <CoreTextarea
          label="Configuration"
          value={values.taskConfig}
          onChange={handlers.taskConfig}
          errorMessage={errors.taskConfig}
          validationState={states.taskConfig}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};
