export const RootUri = '/lms';

const createAddUri = (root: string) => `${root}/add`;
const createDetailUri = (root: string) => (id: string | number) =>
  `${root}/${id}`;
const createEditUri = (root: string) => (id: string | number) =>
  `${createDetailUri(root)(id)}/edit`;

export const DashboardsUri = `${RootUri}/dashboards`;
export const DashboardAddUri = createAddUri(DashboardsUri);
export const DashboardEditUri = createEditUri(DashboardsUri);
export const DashboardDetailUri = createDetailUri(DashboardsUri);

export const LocationsUri = `${RootUri}/locations`;
export const LocationAddUri = createAddUri(LocationsUri);
export const LocationEditUri = createEditUri(LocationsUri);
export const LocationDetailUri = createDetailUri(LocationsUri);

export const LabsUri = `${RootUri}/labs`;
// export const LabAddUri = createAddUri(LabsUri);
export const LabAddUri = (locationName: string): string =>
  `${createAddUri(LabsUri)}?locationName=${locationName}`;
export const LabEditUri = createEditUri(LabsUri);
export const LabDetailUri = createDetailUri(LabsUri);

export const PoolsUri = `${RootUri}/pools`;
export const PoolAddUri = createAddUri(PoolsUri);
export const PoolDetailUri = createDetailUri(PoolsUri);
export const PoolEditUri = createEditUri(PoolsUri);
// export const PoolAddTestrunUri = (poolName: string):
//  string => `${TestrunAddUri}?devicePool=${poolName}`;

export const HostsUri = `${RootUri}/hosts`;
export const HostAddUri = (labName: string): string =>
  `${createAddUri(HostsUri)}?labName=${labName}`;
export const HostEditUri = createEditUri(HostsUri);
export const HostDetailUri = createDetailUri(HostsUri);

export const DevicesUri = `${RootUri}/devices`;
export const DeviceDetailUri = createDetailUri(DevicesUri);
export const DeviceEditUri = createEditUri(DevicesUri);
// export const DeviceAddTestrunUri = (deviceDsn: string):
//  string => `${TestrunAddUri}?device=${deviceDsn}`;
