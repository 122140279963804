import React from 'react';
import {
  CoreProperties,
  CorePropertyColumn,
  CorePropertyDetail,
  CorePropertyRow,
  useToggle,
  CoreRouterLink,
} from '@amzn/dots-core-ui';
import Alert from '@amzn/meridian/alert';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Tooltip from '@amzn/meridian/tooltip';
import { useIdParam } from '$/hooks';
import { useGet, useClient } from '$/api';
import {
  CorePage,
  BuildProperties,
  CorePageTitle,
  CoreDateFromNow,
} from '$/components';
/* eslint-disable import/no-cycle */
import { HostStatusTag } from '$/features/Hosts';
import {
  DeviceEditButton,
  DeviceStatusLabel,
  //  DeviceTestrunsTable,
  //  DeviceAddTestrunButton,
} from './components';
import {
  DevicesUri,
  HostDetailUri,
  PoolDetailUri,
  RootUri,
} from '$/features/routes';

export const DeviceDetailPage = (): JSX.Element => {
  const id = useIdParam();
  const [,] = useToggle();
  const { devices } = useClient();
  const {
    data: device,
    error,
    isError,
    isLoading,
  } = useGet(devices.resourceKey, devices.get, id);
  return (
    <CorePage
      title={device?.dsn}
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Devices', href: DevicesUri },
      ]}
      header={
        <Row widths={['fill', 'fit']}>
          <Column>
            {device?.type?.name && (
              <Heading level={4}>
                <Tooltip title="Device Type" position="bottom">
                  <span>{device.type.name}</span>
                </Tooltip>
              </Heading>
            )}
            {device?.dsn && <CorePageTitle title={device.dsn} />}
            <Row spacing="small">
              <DeviceStatusLabel status={device?.status} />
              {device?.statusModified && (
                <CoreDateFromNow date={device?.statusModified} />
              )}
            </Row>
          </Column>
          <Row alignmentHorizontal="right">
            {device && (
              <>
                <DeviceEditButton device={device} />
              </>
            )}
          </Row>
        </Row>
      }
    >
      <Column spacing="large">
        <CoreProperties>
          <CorePropertyRow>
            <CorePropertyColumn title="Details">
              {device?.devicePools && (
                <CorePropertyDetail label="pool(s)">
                  {device.devicePools.map((pool) => (
                    <CoreRouterLink
                      key={pool.id}
                      href={PoolDetailUri(pool?.id ?? '')}
                    >
                      {pool.name}
                    </CoreRouterLink>
                  ))}
                </CorePropertyDetail>
              )}
              {device?.softwareVersion && (
                <CorePropertyDetail label="SW version">
                  <Text>{device.softwareVersion}</Text>
                </CorePropertyDetail>
              )}
              {device?.hardwareConfiguration?.name && (
                <CorePropertyDetail label="HW config">
                  <Text>{device.hardwareConfiguration.name}</Text>
                </CorePropertyDetail>
              )}
            </CorePropertyColumn>
            <CorePropertyColumn title="Platform Build">
              {device?.build?.id ? (
                <BuildProperties build={device.build} />
              ) : (
                <Alert>
                  <strong>Platform Build</strong> not specified for this device
                </Alert>
              )}
            </CorePropertyColumn>
          </CorePropertyRow>
          <CorePropertyRow>
            <CorePropertyColumn title="Host">
              {device?.host?.id ? (
                <>
                  {device?.host?.name && (
                    <CorePropertyDetail label="name">
                      <CoreRouterLink href={HostDetailUri(device.host.id)}>
                        {device.host.name}
                      </CoreRouterLink>
                    </CorePropertyDetail>
                  )}
                  {device?.host?.version && (
                    <CorePropertyDetail label="version">
                      <Text>v{device.host.version}</Text>
                    </CorePropertyDetail>
                  )}
                  {device?.host?.status && (
                    <CorePropertyDetail label="status">
                      <HostStatusTag status={device.host.status} />
                    </CorePropertyDetail>
                  )}
                </>
              ) : (
                <Alert>This device is not connected to a host</Alert>
              )}
            </CorePropertyColumn>
            {(device?.taskConfig || device?.description) && (
              <CorePropertyColumn title="Configuration">
                {device?.taskConfig && (
                  <CorePropertyDetail label="task">
                    <Text>{device.taskConfig}</Text>
                  </CorePropertyDetail>
                )}
                {device?.description && (
                  <CorePropertyDetail label="description">
                    <Text>{device.description}</Text>
                  </CorePropertyDetail>
                )}
              </CorePropertyColumn>
            )}
          </CorePropertyRow>
        </CoreProperties>
        {
          // device?.dsn && <DeviceTestrunsTable dsn={device.dsn} />
        }
      </Column>
    </CorePage>
  );
};
