import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusIconTokens from '@amzn/meridian-tokens/base/icon/plus';
import { useHistory } from 'react-router-dom';
import { ButtonProps } from '@amzn/meridian/button/button';
import { LabAddUri } from '$/features/routes';
import { LMSLocation } from '$/api';

export type LabAddButtonProps = {
  lmslocation?: LMSLocation;
  size?: ButtonProps['size'];
};

export const LabAddButton = ({
  lmslocation,
  size = 'medium',
}: LabAddButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Button
      size={size}
      type={size === 'small' ? 'icon' : 'tertiary'}
      onClick={() => {
        history.push(LabAddUri(lmslocation?.name ? lmslocation?.name : ''));
      }}
    >
      <Icon tokens={plusIconTokens} />
      Add New
    </Button>
  );
};
