import React from 'react';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import DownloadTokens from '@amzn/meridian-tokens/base/icon/download-large';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';

import { useClient, useGetAll } from '$/api';
import { CorePage, CorePageTitle, CoreSearchTable } from '$/components';
/* eslint-disable import/no-cycle */
import { HostAddButton, HostColumnDefinitions } from './components';
import { RootUri } from '$/features/routes';
import { environment } from '$/environments';

const filters = [
  { key: 'name', label: 'Name', isDefault: true },
  { key: 'owner', label: 'Owner' },
  { key: 'status', label: 'Status' },
  { key: 'createdBy', label: 'Created By' },
  { key: 'lab', label: 'Lab' },
  { key: 'location', label: 'Location' },
  { key: 'version', label: 'Version' },
];

export const HostListPage = (): JSX.Element => {
  const { hosts } = useClient();
  const { data, isLoading } = useGetAll(hosts.resourceKey, hosts.getAll, {});

  /* eslint-disable prettier/prettier */
  return (
    <CorePage
      title="Host Agents"
      header={
        <>
          <Row widths={['fill', 'fit']}>
            <Column>
              <CorePageTitle title="Host Agents" />
              <Column spacing="xxsmall">
                <Text>
                  children={!isLoading ? `Total: ${data?.total}` : ''}
                </Text>
              </Column>
            </Column>
            <Row alignmentHorizontal="right">
              <HostAddButton />
            </Row>
          </Row>
        </>
      }
      breadcrumbs={[{ title: 'LMS Lab Dashboard', href: RootUri }]}
    >
      <Alert type="informational">
        Download your host agent here!
        <Button
          type="icon"
          onClick={() =>
            window.open(
              // https://api.scheduler.dots.lab126.a2z.com/api/v3/new_updates/version_data/download
              `${environment.oldSchedulingApiUrl}/new_updates/version_data/download`,
              '_blank'
            )
          }
        >
          <Icon tokens={DownloadTokens}>Download</Icon>
        </Button>
      </Alert>
      <CoreSearchTable
        resourceKey={hosts.resourceKey}
        getAll={hosts.getAll}
        resourceFilters={filters}
        columns={HostColumnDefinitions}
      />
    </CorePage>
  );
};
