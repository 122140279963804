import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import Tooltip from '@amzn/meridian/tooltip';
import { Device } from '$/api';
import { DeviceEditUri } from '$/features/routes';

export type DeviceEditButtonProps = {
  device: Device;
};

export const DeviceEditButton = ({
  device,
}: DeviceEditButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip title={`edit ${device.dsn}`} position="bottom">
      <Button
        type="tertiary"
        onClick={() => history.push(DeviceEditUri(device.id))}
      >
        <Icon tokens={pencilIconTokens} />
      </Button>
    </Tooltip>
  );
};
