import React from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import {
  CoreTextarea,
  CoreFormFooter,
  useForm,
  CoreInput,
  isNullOrWhitespace,
  isEmailString,
} from '@amzn/dots-core-ui';
import { Host } from '$/api';
import { GroupSelect } from '$/components';

export type HostFormProps = {
  host?: Host;
  hostname?: string;
  rsapub?: string;
  version?: string;
  onSubmit: (host: Partial<Host>) => void;
  onCancel: () => void;
};

export const HostForm = ({
  host,
  hostname,
  rsapub,
  version,
  onSubmit,
  onCancel,
}: HostFormProps): JSX.Element => {
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    hostname: {
      initialValue: host?.name ?? hostname ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value)
          ? 'HostName is required when registering a new host'
          : '',
    },
    owner: { initialValue: host?.owner ?? '' },
    rsaPublicKey: {
      initialValue: host?.rsaPublicKey ?? rsapub ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value)
          ? 'RSA Public Key is required when registering a new host'
          : '',
    },
    version: { initialValue: host?.version ?? version ?? '' },
    email: {
      initialValue: host?.email ?? '',
      validate: (value): string =>
        !isEmailString(value) ? 'Email string is not proper format' : '',
    },
  });

  return (
    <form
      onSubmit={handleSubmit((vs) => {
        const payload: Record<string, any> = {
          name: vs.hostname,
          rsaPublicKey: vs.rsaPublicKey,
          owner: vs.owner,
          email: vs.email,
          version: vs.version,
          status: 'online',
        };
        return onSubmit(payload);
      })}
    >
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        {!host && !hostname && !rsapub && (
          <Alert type="error" title="Error!" size="large">
            {errors.hostname}
          </Alert>
        )}
        {!host && (
          <>
            <CoreInput
              disabled
              label="Hostname"
              value={values.hostname}
              onChange={handlers.hostname}
              errorMessage={errors.hostname}
              validationState={states.hostname}
            />
            <CoreInput
              disabled
              label="Version"
              value={values.version}
              onChange={handlers.version}
              errorMessage={errors.version}
              validationState={states.version}
            />
          </>
        )}
        <GroupSelect
          value={values.owner}
          onChange={handlers.owner}
          errorMessage={errors.owner}
          validationState={states.owner}
        />
        <CoreInput
          label="Team Email"
          value={values.email}
          onChange={handlers.email}
          errorMessage={errors.email}
          validationState={states.email}
        />
        <CoreTextarea
          label="RSA Public Key (PEM Format)"
          value={values.rsaPublicKey}
          onChange={handlers.rsaPublicKey}
          errorMessage={errors.rsaPublicKey}
          validationState={states.rsaPublicKey}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};
