import React from 'react';
import { CoreAsyncButton, CoreTable } from '@amzn/dots-core-ui';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import { Device, LMSLocation, useClient, useInfiniteGetAll } from '$/api';
import {
  /* DeviceAddButton, */ DeviceColumnDefinitions,
} from '$/features/Devices';

type LocationDevicesTableProps = {
  lmslocation?: LMSLocation;
};
export const LocationDevicesTable = ({
  lmslocation,
}: LocationDevicesTableProps): JSX.Element => {
  const { devices } = useClient();
  const locationId = lmslocation?.id;
  const { data, allData, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteGetAll(devices.resourceKey, devices.getAll, { locationId });
  return (
    <Column spacing="small">
      <Heading level={3}>
        <Row widths="fill">
          Devices
          {/*
          <Row alignmentHorizontal="right">
            <DeviceAddButton lmslocation={lmslocation} />
          </Row>
          */}
        </Row>
      </Heading>
      <CoreTable
        fixHeaderRows
        isLoading={isLoading && !data}
        columnDefinitions={DeviceColumnDefinitions}
        getItemId={(item: Device) => item.id}
        items={allData}
      />
      <Row alignmentHorizontal="center">
        <CoreAsyncButton
          disabled={!hasNextPage}
          type="tertiary"
          onClick={async (): Promise<void> => {
            await fetchNextPage();
          }}
        >
          Load More
        </CoreAsyncButton>
      </Row>
    </Column>
  );
};

LocationDevicesTable.defaultProps = {
  lmslocation: '',
};
