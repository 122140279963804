/* eslint-disable no-param-reassign */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  ColumnDefinition,
  CoreAsyncButton,
  CoreTable,
  CoreTableSettingsButton,
  HasId,
  isNullOrWhitespace,
  useLocalStorage,
} from '@amzn/dots-core-ui';
import Alert from '@amzn/meridian/alert';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import SearchField from '@amzn/meridian/search-field';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Popover from '@amzn/meridian/popover';
import Column from '@amzn/meridian/column';
import ControlGroup from '@amzn/meridian/control-group';
import RadioButton from '@amzn/meridian/radio-button';
import Badge from '@amzn/meridian/badge';
import FilterIconTokens from '@amzn/meridian-tokens/base/icon/filter';
import { BaseGetAllFilter, GetAllApi, useGetAll } from '$/api';
import { useSearchParams } from '$/hooks';

export type CoreSearchFilterPickerProps = {
  filters: string[];
  value: string;
  onChange: (filter: string) => void;
};

export const CoreSearchFilterPicker = ({
  filters,
  value,
  onChange,
}: CoreSearchFilterPickerProps): JSX.Element => {
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const buttonRef = useRef();
  return (
    <>
      <Button
        ref={buttonRef}
        onClick={(): void => setFilterPopoverOpen(true)}
        type="icon"
      >
        <Icon tokens={FilterIconTokens}>
          <Badge>Filters</Badge>
        </Icon>
      </Button>
      <Popover
        anchorNode={buttonRef.current}
        open={filterPopoverOpen}
        onClose={(): void => setFilterPopoverOpen(false)}
        position="bottom"
      >
        <Column>
          <ControlGroup label="Search by" onChange={onChange} value={value}>
            {filters.map((option) => (
              <RadioButton key={option} value={option}>
                {option}
              </RadioButton>
            ))}
          </ControlGroup>
        </Column>
      </Popover>
    </>
  );
};

// We need a way to map select filter labels, keys and if the filter should
// be consisdered the default filter
export type ResourceFilter = {
  key: string;
  label: string;
  isDefault?: boolean;
};

export type CoreSearchTableProps<
  TId,
  TModel extends HasId<number | string>,
  TFilter extends BaseGetAllFilter<TId>
> = {
  infinite?: boolean;
  actions?: React.ReactNode;
  resourceKey: string;
  getAll: GetAllApi<TId, TModel, TFilter>;
  resourceFilters: ResourceFilter[];
  columns: ColumnDefinition<TModel>[];
};

export const CoreSearchTable = <
  TId,
  TModel extends HasId<number | string>,
  TFilter extends BaseGetAllFilter<TId>
>({
  infinite = false,
  actions = undefined,
  resourceKey,
  getAll,
  resourceFilters,
  columns,
}: CoreSearchTableProps<TId, TModel, TFilter>): JSX.Element => {
  const filterLabels = useMemo(
    () => resourceFilters.map((f) => f.label),
    [resourceFilters]
  );
  const defaultResourceFilter = useMemo(
    () => resourceFilters.filter((rf) => rf.isDefault)[0],
    [resourceFilters]
  );
  // We will need a way to build a filter object that can be sent to the API
  // later. The field we will use to determine which filter to target will
  // change depending on the context.
  const getResourceFilter = useCallback(
    (filter: string, match: (f: ResourceFilter) => string) =>
      resourceFilters.find((rf) => filter && filter === match(rf)) ??
      defaultResourceFilter,
    [defaultResourceFilter, resourceFilters]
  );
  const searchParams = useSearchParams();
  const [selectedFilterLabel, setSelectedFilterLabel] = useState<string>(
    resourceFilters.find((f) => searchParams.filter === f.key)?.label ?? ''
  );
  const submitSearch = useCallback(
    (query: string) => {
      const filter = getResourceFilter(selectedFilterLabel, (rf) => rf.label);
      return searchParams.onSearchSubmit(query, filter?.key ?? '');
    },
    [getResourceFilter, searchParams, selectedFilterLabel]
  );
  const [query, setQuery] = useState(searchParams.q);
  const [visibleColumnIds, setVisibleColumnIds] = useLocalStorage(
    `${resourceKey}-list-columns`,
    columns.map((col) => col.id)
  );
  const [pageSize, setPageSize] = useLocalStorage(
    `${resourceKey}-list-page-size`,
    25
  );
  const { data, error, isError, isFetching } = useGetAll(resourceKey, getAll, {
    page: searchParams.page,
    pageSize,
    fuzzySearch: true,
    [getResourceFilter(searchParams.filter, (f) => f.key).key]: searchParams.q,
  } as unknown as TFilter);
  const { count: responseCount, pageSize: responsePageSize } = data || {};
  const hasNextPage = useMemo(
    () => responseCount === responsePageSize,
    [responseCount, responsePageSize]
  );
  useEffect(() => {
    const f = getResourceFilter(searchParams.filter, (rf) => rf.key);
    setSelectedFilterLabel(f?.label ?? '');
    setQuery(searchParams.q);
  }, [searchParams.q, resourceFilters, searchParams.filter, getResourceFilter]);
  if (isError) {
    return (
      <Alert type="error" size="xlarge">
        {error?.message}
      </Alert>
    );
  }
  return (
    <>
      <CoreTable
        actions={
          <>
            <Row>
              <SearchField
                autoFocus
                value={query}
                onChange={setQuery}
                onSubmit={submitSearch}
                onClear={searchParams.onSearchSubmit}
              />
              <CoreSearchFilterPicker
                filters={filterLabels}
                value={selectedFilterLabel}
                onChange={(f) => setSelectedFilterLabel(f)}
              />
              {!isNullOrWhitespace(searchParams.q) &&
                !isFetching &&
                data?.total !== undefined && <Text>{data.total} results</Text>}
            </Row>
            <Row>
              {actions}
              <CoreTableSettingsButton
                columnDefinitions={columns}
                visibleColumnIds={visibleColumnIds}
                onColumnDefinitionsIdsChange={setVisibleColumnIds}
                selectedPageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </Row>
          </>
        }
        fixHeaderRows
        columnDefinitions={columns.filter(
          (col) => visibleColumnIds.indexOf(col.id) !== -1
        )}
        getItemId={(item: TModel) => item.id}
        items={data?.content}
        isLoading={isFetching}
        pagination={
          !infinite
            ? {
                onPageChange: searchParams.onPageChange,
                currentPage: searchParams.page,
                numberOfPages: data?.pageTotal,
              }
            : undefined
        }
      />
      {infinite && (
        <Row alignmentHorizontal="center">
          <CoreAsyncButton
            disabled={!hasNextPage}
            type="tertiary"
            onClick={async (): Promise<void> => {
              await searchParams.onPageChange(searchParams.page + 1);
            }}
          >
            Next Page
          </CoreAsyncButton>
        </Row>
      )}
    </>
  );
};
