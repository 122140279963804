import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import desktopIconTokens from '@amzn/meridian-tokens/base/icon/desktop';
import Tooltip from '@amzn/meridian/tooltip';
import { Host } from '$/api';

export type HostAgentLinkButtonProps = {
  host: Host;
  hostUrl: string;
};

export const HostAgentLinkButton = ({
  host,
  hostUrl,
}: HostAgentLinkButtonProps): JSX.Element => {
  return (
    <Tooltip title={`Go to ${host.name}`} position="bottom">
      <Button
        type="tertiary"
        href={hostUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon tokens={desktopIconTokens} />
      </Button>
    </Tooltip>
  );
};
