import { HttpClient } from '@amzn/dots-core-ui';
import { LMSLocation } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type LMSLocationId = LMSLocation['id'];

export type LMSLocationGetAllFilter = BaseGetAllFilter<number> & {
  name?: string;
  description?: string;
};

export type LMSLocationApi = BaseApi<
  number,
  LMSLocation,
  LMSLocationGetAllFilter
>;

export const LMSLocationClient = (client: HttpClient): LMSLocationApi => ({
  ...baseApiFactory(client, 'location'),
});
