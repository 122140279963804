import { useToaster } from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LMSDashboard, useClient, useGet, useUpdate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import { DashboardForm } from './components';
import { DashboardDetailUri, DashboardsUri, RootUri } from '$/features/routes';

export const DashboardEditPage = (): JSX.Element => {
  const id = useIdParam();
  const history = useHistory();
  const { openToast } = useToaster();
  const { dashboards } = useClient();
  const {
    data: dashboard,
    error,
    isError,
    isLoading,
  } = useGet(dashboards.resourceKey, dashboards.get, id);
  const { mutateAsync } = useUpdate(dashboards.resourceKey, dashboards.update);
  const onSubmit = useCallback(
    async (item: Partial<LMSDashboard>): Promise<void> => {
      await mutateAsync({ id, item });
      openToast({
        title: 'Success!',
        message: `${dashboard?.name} has been updated.`,
        type: 'success',
      });
      history.push(DashboardDetailUri(id));
    },
    [mutateAsync, id, openToast, dashboard, history]
  );
  return (
    <CorePage
      title="Edit"
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Dashboards', href: DashboardsUri },
        { title: dashboard?.name ?? '', href: DashboardDetailUri(id) },
      ]}
      header={
        <CorePageTitle title={dashboard ? `Edit ${dashboard?.name}` : ''} />
      }
    >
      <DashboardForm
        onCancel={() => history.push(DashboardDetailUri(id))}
        onSubmit={onSubmit}
        dashboard={dashboard}
      />
    </CorePage>
  );
};
