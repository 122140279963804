import React from 'react';
// import React, { useState } from 'react';
// import Tab, { TabGroup } from '@amzn/meridian/tab';
// import Alert from '@amzn/meridian/alert';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
// import Link from '@amzn/meridian/link';

// import Heading from '@amzn/meridian/heading';
// import Divider from '@amzn/meridian/divider';
import { useGet, useClient } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
/* eslint-disable import/no-cycle */
import {
  HostStatusTag,
  HostEditButton,
  HostAgentLinkButton,
  HostDevicesTable,
  // HostTasksTable,
} from './components';
import { HostsUri, RootUri } from '$/features/routes';

export const HostDetailPage = (): JSX.Element => {
  const id = useIdParam();
  const { hosts } = useClient();
  // const [currentTab, setCurrentTab] = useState('devices');
  const {
    data: host,
    error,
    isError,
    isLoading,
  } = useGet(hosts.resourceKey, hosts.get, id);

  const hostUrl = String(`https://${host?.name}:12601/`);

  return (
    <CorePage
      title={host?.name}
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Host Agents', href: HostsUri },
      ]}
      header={
        <>
          {host?.status && (
            <Row alignmentHorizontal="right">
              <HostStatusTag status={host?.status} />
            </Row>
          )}
          <Row widths={['fill', 'fit']}>
            <Column>
              {host?.name && <CorePageTitle title={host.name} />}
              <Column spacing="xxsmall">
                {host?.version && <Text>Version: {host.version}</Text>}
                {host?.owner && <Text>Owner: {host.owner}</Text>}
              </Column>
            </Column>
            {host && (
              <Row alignmentHorizontal="right">
                <HostAgentLinkButton host={host} hostUrl={hostUrl} />
                {/* <Link href={hostUrl} target="_blank" rel="noopener noreferrer">
                  KATS Host Agent
                  <Icon tokens={homeIconTokens} />
                </Link> */}
                <HostEditButton host={host} />
              </Row>
            )}
          </Row>
        </>
      }
    >
      {/* 
      <Column spacing="large">
        <Column spacingInset="medium" type="outline">
          <Heading level={3}>RSA Public Key</Heading>
          <Divider />
          {host?.rsaPublicKey ? (
            <Column spacingInset="medium" type="fill">
              <Text tag="pre">{host.rsaPublicKey}</Text>
            </Column>
          ) : (
            <Alert>This host is not configured</Alert>
          )}
        </Column>
      </Column>
      */}
      <Column spacing="large">
        <Column spacingInset="medium" type="outline">
          {host?.id && <HostDevicesTable host={host.name} />}
        </Column>
      </Column>
      {/* 
      <Column spacing="large">
        <Column spacingInset="medium" type="outline">
          {host?.id && <HostTasksTable host={host.name} />}
        </Column>
      </Column>
      */}
      {/*

      <Column spacing="large">
        <TabGroup
          value={currentTab}
          onChange={setCurrentTab}
          type="classic"
          fill="tabs"
        >
          <Tab value={host?.id && <HostDevicesTable host={host.name} />}>
            Devices
          </Tab>
          <Tab value={host?.id && <HostTasksTable host={host.name} />}>
            Tasks
          </Tab>
          <Tab value="tab-3">Workflows</Tab>
        </TabGroup>
        {currentTab}
      </Column>
    */}
    </CorePage>
  );
};
