import { useQueryString, useToaster } from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LMSLocation, useClient, useCreate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { LocationForm } from './components';
import { LocationDetailUri, LocationsUri, RootUri } from '$/features/routes';

export type LocationQueryParams = {
  name: string;
  description: string;
};

export const LocationAddPage = (): JSX.Element => {
  const { params } = useQueryString<LocationQueryParams>({
    name: '',
    description: '',
  });
  const history = useHistory();
  const { openToast } = useToaster();
  const { lmslocations } = useClient();
  const { mutateAsync } = useCreate(
    lmslocations.resourceKey,
    lmslocations.create
  );
  const onSubmit = useCallback(
    async (lmslocation: Partial<LMSLocation>): Promise<void> => {
      const response = await mutateAsync(lmslocation);
      openToast({
        title: 'Success!',
        message: `The ${response?.name} location was registered`,
        type: 'success',
      });
      history.push(LocationDetailUri(response.id));
    },
    [history, lmslocations, params, mutateAsync, openToast]
  );
  return (
    <CorePage
      title="Add Location"
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Locations', href: LocationsUri },
      ]}
      header={<CorePageTitle title="Add a New Location" />}
    >
      <LocationForm
        onSubmit={onSubmit}
        onCancel={() => history.push(LocationsUri)}
        name={params.name}
        description={params.description}
      />
    </CorePage>
  );
};
