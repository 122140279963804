import React from 'react';
import Alert from '@amzn/meridian/alert';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import { useGet, useClient } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import { DashboardStatusTag, DashboardEditButton } from './components';
import { DashboardsUri, RootUri } from '$/features/routes';

export const DashboardDetailPage = (): JSX.Element => {
  const id = useIdParam();
  const { dashboards } = useClient();
  const {
    data: dashboard,
    error,
    isError,
    isLoading,
  } = useGet(dashboards.resourceKey, dashboards.get, id);
  return (
    <CorePage
      title={dashboard?.name}
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Dashboards', href: DashboardsUri },
      ]}
      header={
        <>
          {dashboard?.status && (
            <Row>
              <DashboardStatusTag status={dashboard?.status} />
            </Row>
          )}
          <Row widths={['fill', 'fit']}>
            <Column>
              {dashboard?.name && <CorePageTitle title={dashboard.name} />}
              <Column spacing="xxsmall">
                {dashboard?.version && (
                  <Text>Version: {dashboard.version}</Text>
                )}
                {dashboard?.owner && <Text>Owner: {dashboard.owner}</Text>}
              </Column>
            </Column>
            {dashboard && (
              <Row alignmentHorizontal="right">
                <DashboardEditButton dashboard={dashboard} />
              </Row>
            )}
          </Row>
        </>
      }
    >
      <Column spacing="large">
        <Column spacingInset="medium" type="outline">
          <Heading level={3}>RSA Public Key</Heading>
          <Divider />
          {dashboard?.rsaPublicKey ? (
            <Column spacingInset="medium" type="fill">
              <Text tag="pre">{dashboard.rsaPublicKey}</Text>
            </Column>
          ) : (
            <Alert>This dashboard is not configured</Alert>
          )}
        </Column>
      </Column>
    </CorePage>
  );
};
