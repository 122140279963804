import { HttpClient } from '@amzn/dots-core-ui';
import { DeviceType } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type DeviceTypeId = DeviceType['id'];

export type DeviceTypeGetAllFilter = BaseGetAllFilter<DeviceTypeId> & {
  name?: string;
  platform?: string;
};

export type DeviceTypeApi = BaseApi<
  DeviceTypeId,
  DeviceType,
  DeviceTypeGetAllFilter
>;

export const DeviceTypeClient = (client: HttpClient): DeviceTypeApi => ({
  ...baseApiFactory(client, 'devicetype'),
});
