import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import Tooltip from '@amzn/meridian/tooltip';
import { LMSLocation } from '$/api';
import { LocationEditUri } from '$/features/routes';

export type LocationEditButtonProps = {
  lmslocation: LMSLocation;
};

export const LocationEditButton = ({
  lmslocation,
}: LocationEditButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip title={`Edit ${lmslocation.name}`} position="bottom">
      <Button
        type="tertiary"
        onClick={() => history.push(LocationEditUri(lmslocation.id))}
      >
        <Icon tokens={pencilIconTokens} />
      </Button>
    </Tooltip>
  );
};
