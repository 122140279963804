import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { Lab } from './models';

export type LabApi = {
  getAll: () => Promise<ListResponse<Lab>>;
};

export const LabClient = (client: HttpClient): LabApi => ({
  getAll: (): Promise<ListResponse<Lab>> => client.get({ url: '/lab' }),
});
