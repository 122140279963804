import React from 'react';
import Tag from '@amzn/meridian/tag';
import { TagProps } from '@amzn/meridian/tag/tag';
import { LMSDashboardStatus } from '$/api';

export const DashboardStatusTag = ({
  status,
}: {
  status: LMSDashboardStatus;
}): JSX.Element => {
  let type: TagProps['type'] = 'neutral';
  if (status === 'online') {
    type = 'success';
  } else if (status === 'updating') {
    type = 'theme';
  } else if (status === 'deprecated') {
    type = 'error';
  }
  return <Tag type={type}>{status}</Tag>;
};
