import { HttpClient, TokenCreator } from '@amzn/dots-core-ui';
import { ConfigurationApi, ConfigurationClient } from './configurations';
import { PlanApi, PlanClient } from './plans';
import { VersionApi, VersionClient } from './versions';

export * from './configurations';
export * from './models';
export * from './plans';
export * from './versions';

export interface TestManagementApi {
  configurations: ConfigurationApi;
  plans: PlanApi;
  versions: VersionApi;
}

export const createTestManagementClient = (
  testManagementApiUrl: string,
  createToken: TokenCreator
): TestManagementApi => {
  const client = new HttpClient(testManagementApiUrl, createToken);
  return {
    configurations: ConfigurationClient(client),
    plans: PlanClient(client),
    versions: VersionClient(client),
  };
};
