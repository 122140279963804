import { HttpClient } from '@amzn/dots-core-ui';
import { Version } from './models';
import { BaseGetAllFilter, BaseReadApi, baseReadApiFactory } from '../baseApi';

export type VersionId = Version['id'];

export interface VersionGetAllFilter extends BaseGetAllFilter<VersionId> {
  packageId: number;
}

export type VersionApi = BaseReadApi<VersionId, Version, VersionGetAllFilter>;

export const VersionClient = (client: HttpClient): VersionApi => ({
  ...baseReadApiFactory(client, 'version'),
});
