import { HttpClient } from '@amzn/dots-core-ui';
import { Configuration } from './models';
import { BaseGetAllFilter, BaseReadApi, baseReadApiFactory } from '../baseApi';

export type ConfigurationId = Configuration['id'];

export interface ConfigurationGetAllFilter
  extends BaseGetAllFilter<ConfigurationId> {
  name?: string;
  config?: string;
  packageCreatedBy?: string;
  packageId?: number;
  packageType?: string;
  isFavorite?: boolean;
  ids?: number[];
  page?: number;
  pageSize?: number;
}

export type ConfigurationApi = BaseReadApi<
  ConfigurationId,
  Configuration,
  ConfigurationGetAllFilter
>;

export const ConfigurationClient = (client: HttpClient): ConfigurationApi => ({
  ...baseReadApiFactory(client, 'configuration'),
});
