import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { UpdateApi } from '../clients';

export const useUpdate = <TId, TModel, TMutationModel = Partial<TModel>>(
  resourceKey: string,
  update: UpdateApi<TId, TModel, TMutationModel>,
  optimistic = true
): UseMutationResult<
  TModel,
  Error,
  {
    id: TId;
    item: TMutationModel;
  },
  (() => void) | undefined
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, item }) => update(id, item), {
    onMutate: ({ id, item }) => {
      const queryKey = [resourceKey, { id }];
      queryClient.cancelQueries(queryKey);
      if (optimistic) {
        const previous = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, { id, ...item });
        return () => queryClient.setQueryData(queryKey, previous);
      }
      return undefined;
    },
    onSettled: (_, error, { id }, rollback) => {
      if (error && rollback) {
        rollback();
      }
      queryClient.invalidateQueries([resourceKey, { id }]);
      queryClient.invalidateQueries([`${resourceKey}s`]);
    },
  });
};
