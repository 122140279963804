import { UserManagerSettings } from 'oidc-client';
import {
  lassoProductionConfiguration,
  lassoStagingConfiguration,
} from '@amzn/dots-core-ui';

export interface Environment {
  hosts: string[];
  searchApiUrl: string;
  schedulingApiUrl: string;
  oldSchedulingApiUrl: string;
  testManagementApiUrl: string;
  labDashboardApiUrl: string;
  userManagerSettings: UserManagerSettings;
}

const environments: Environment[] = [
  {
    // Alpha
    hosts: [
      'scheduler-alpha.dots.lab126.a2z.com',
      'server-alpha.dots.lab126.a2z.com',
      'ojyild-cdd.aka.corp.amazon.com',
    ],
    searchApiUrl: 'https://search-beta.dots.lab126.a2z.com',
    schedulingApiUrl: 'https://api.scheduler-alpha.dots.lab126.a2z.com/api/v4',
    // eslint-disable-next-line prettier/prettier
    oldSchedulingApiUrl:
      'https://api.scheduler-alpha.dots.lab126.a2z.com/api/v3',
    testManagementApiUrl: 'https://api.tms-beta.dots.lab126.a2z.com/api/v3',
    labDashboardApiUrl:
      'https://api.scheduler-alpha.dots.lab126.a2z.com/api/v4',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Beta
    hosts: [
      'localhost',
      'braunera.aka.corp.amazon.com',
      'xevan.aka.corp.amazon.com',
      'saurade.aka.corp.amazon.com',
      'tools-beta.dots.lab126.a2z.com',
      'scheduler-beta.dots.lab126.a2z.com',
      'server-beta.dots.lab126.a2z.com',
      'ojyild-cdd.aka.corp.amazon.com',
      'dashboard-beta.lmsdashboard.lab126.amazon.dev',
    ],
    searchApiUrl: 'https://search-beta.dots.lab126.a2z.com',
    schedulingApiUrl: 'https://api.scheduler-beta.dots.lab126.a2z.com/api/v4',
    // eslint-disable-next-line prettier/prettier
    oldSchedulingApiUrl:
      'https://api.scheduler-beta.dots.lab126.a2z.com/api/v3',
    testManagementApiUrl: 'https://api.tms-beta.dots.lab126.a2z.com/api/v3',
    labDashboardApiUrl: 'https://api.scheduler-beta.dots.lab126.a2z.com/api/v4',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Gamma
    hosts: [
      'scheduler-gamma.dots.lab126.a2z.com',
      'server-gamma.dots.lab126.a2z.com',
    ],
    searchApiUrl: 'https://search.dots.lab126.a2z.com',
    schedulingApiUrl: 'https://api.scheduler-gamma.dots.lab126.a2z.com/api/v4',
    // eslint-disable-next-line prettier/prettier
    oldSchedulingApiUrl:
      'https://api.scheduler-gamma.dots.lab126.a2z.com/api/v3',
    testManagementApiUrl: 'https://api.tms.dots.lab126.a2z.com/api/v3',
    labDashboardApiUrl:
      'https://api.scheduler-gamma.dots.lab126.a2z.com/api/v4',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Prod
    hosts: [
      'tools.dots.lab126.a2z.com',
      'scheduler.dots.lab126.a2z.com',
      'server.dots.lab126.a2z.com',
      'dashboard.lmsdashboard.lab126.amazon.dev',
    ],
    searchApiUrl: 'https://search.dots.lab126.a2z.com',
    schedulingApiUrl: 'https://api.scheduler.dots.lab126.a2z.com/api/v4',
    oldSchedulingApiUrl: 'https://api.scheduler.dots.lab126.a2z.com/api/v3',
    testManagementApiUrl: 'https://api.tms.dots.lab126.a2z.com/api/v3',
    labDashboardApiUrl: 'https://api.scheduler.dots.lab126.a2z.com/api/v4',
    userManagerSettings: lassoProductionConfiguration,
  },
];

const match = environments.filter(
  (config) => config.hosts.indexOf(window.location.hostname) > -1
);
if (match.length === 0) {
  throw new Error(`${window.location.hostname} is not configured`);
}

export const [environment] = match;
