import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import { LMSDashboard } from '$/api';
import { DashboardStatusTag } from './DashboardStatusTag';
import { DashboardDetailUri } from '$/features/routes';

export const DashboardColumnDefinitions: ColumnDefinition<LMSDashboard>[] = [
  {
    id: 'dashboard',
    header: 'Dashboard',
    item: ({ id, name }): JSX.Element => (
      <CoreRouterLink href={DashboardDetailUri(id)}>{name}</CoreRouterLink>
    ),
  },
  {
    id: 'status',
    header: 'Status',
    item: ({ status }): JSX.Element => <DashboardStatusTag status={status} />,
  },
  {
    id: 'version',
    header: 'Version',
    item: (dashboard): string => dashboard.version,
  },
  {
    id: 'owner',
    header: 'Owner',
    item: (dashboard): string => dashboard.owner,
  },
];
