import { HttpClient } from '@amzn/dots-core-ui';
import { Host } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type HostId = Host['id'];

export type HostGetAllFilter = BaseGetAllFilter<HostId> & {
  name?: string;
  owner?: string;
  email?: string;
  status?: string;
  createdBy?: string;
  lab?: string;
  location?: string;
  version?: string;
  labId?: number;
  locationId?: number;
};

export type HostApi = BaseApi<HostId, Host, HostGetAllFilter>;

export const HostClient = (client: HttpClient): HostApi => ({
  ...baseApiFactory(client, 'host'),
});
