import React from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import {
  CoreTextarea,
  CoreFormFooter,
  useForm,
  CoreInput,
  isNullOrWhitespace,
} from '@amzn/dots-core-ui';
import { LMSLocation, useClient } from '$/api';

export type LocationFormProps = {
  lmslocation?: LMSLocation;
  name?: string;
  description?: string;
  onSubmit: (lmslocation: Partial<LMSLocation>) => Promise<void> | void;
  onCancel: () => void;
};

export const LocationForm = ({
  lmslocation,
  onSubmit,
  onCancel,
}: LocationFormProps): JSX.Element => {
  const { lmslocations } = useClient();
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    name: {
      initialValue: lmslocation?.name ?? '',
      validate: async (value: string): Promise<string> => {
        if (isNullOrWhitespace(value)) {
          return 'Name is required';
        }
        if (!/^[a-zA-Z0-9.\-_ ]+$/.test(value)) {
          return 'Name can only contain letters, numbers, spaces, and ._- characters';
        }
        if (value === lmslocation?.name) {
          return '';
        }
        const result = await lmslocations.getAll({ name: value });
        if (result.count > 0) {
          return `${value} is already in use`;
        }
        return '';
      },
    },
    description: { initialValue: lmslocation?.description ?? '' },
  });

  return (
    <form
      onSubmit={handleSubmit((vs) => {
        const payload = { ...vs };
        payload.name = payload.name.trim();
        payload.description = payload.description.trim();
        return onSubmit(vs);
      })}
    >
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <CoreInput
          label="Name"
          value={values.name}
          onChange={handlers.name}
          errorMessage={errors.name}
          validationState={states.name}
        />
        <CoreTextarea
          label="Description"
          value={values.description}
          onChange={handlers.description}
          errorMessage={errors.description}
          validationState={states.description}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};
