import React from 'react';
import Text from '@amzn/meridian/text';
import { CorePropertyDetail } from '@amzn/dots-core-ui';
import { Build } from '$/api';

export type BuildPropertiesProps = {
  build: Build;
};

export const BuildProperties = ({
  build,
}: BuildPropertiesProps): JSX.Element => {
  return (
    <>
      {build?.version && (
        <CorePropertyDetail label="version">
          <Text>{build.version}</Text>
        </CorePropertyDetail>
      )}
      {build?.type?.name && (
        <CorePropertyDetail label="type">
          <Text>{build.type.name}</Text>
        </CorePropertyDetail>
      )}
      {build?.type?.project?.name && (
        <CorePropertyDetail label="project">
          <Text>{build.type.project.name}</Text>
        </CorePropertyDetail>
      )}
      {build?.type?.project?.platform && (
        <CorePropertyDetail label="platform">
          <Text>{build.type.project.platform}</Text>
        </CorePropertyDetail>
      )}
    </>
  );
};
