import React from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import {
  CoreAuth,
  RemoteLifecyleEventArgs,
  mountReactComponent,
  RemoteApplicationAdapter,
  unmountReactComponent,
} from '@amzn/dots-core-ui';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppRouter, AppNavigation, App } from './features';
import { ClientProvider } from './api';
import { environment } from './environments';

declare global {
  interface Window {
    LMSLabDashboard: RemoteApplicationAdapter;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // do not trigger a refetch when the window re-focuses
      refetchOnWindowFocus: false,
      // don't retry failed requests, it probably won't help
      retry: 0,
    },
  },
});

window.LMSLabDashboard = {
  mount: ({ containerId }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CoreAuth userManagerSettings={environment.userManagerSettings}>
            <ClientProvider
              searchApiUrl={environment.searchApiUrl}
              schedulingApiUrl={environment.schedulingApiUrl}
              testManagementApiUrl={environment.testManagementApiUrl}
              labDashboardApiUrl={environment.labDashboardApiUrl}
            >
              <App />
            </ClientProvider>
          </CoreAuth>
        </BrowserRouter>
      </QueryClientProvider>,
      containerId
    ),
  mountRouter: ({
    containerId,
    history,
    createToken,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <ClientProvider
            searchApiUrl={environment.searchApiUrl}
            schedulingApiUrl={environment.schedulingApiUrl}
            testManagementApiUrl={environment.testManagementApiUrl}
            labDashboardApiUrl={environment.labDashboardApiUrl}
            createToken={createToken}
          >
            <AppRouter isRootApp={false} />
          </ClientProvider>
        </Router>
      </QueryClientProvider>,
      containerId
    ),
  mountNavigation: ({
    containerId,
    history,
    isMenuOpen,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <AppNavigation isMenuOpen={isMenuOpen} />
        </Router>
      </QueryClientProvider>,
      containerId
    ),
  unmountRouter: unmountReactComponent,
  unmountNavigation: unmountReactComponent,
};
