import { HttpClient } from '@amzn/dots-core-ui';
import { LMSDashboard } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type LMSDashboardId = LMSDashboard['id'];

export type LMSDashboardGetAllFilter = BaseGetAllFilter<LMSDashboardId> & {
  name?: string;
  owner?: string;
  email?: string;
  status?: string;
  createdBy?: string;
  lab?: string;
  location?: string;
  version?: string;
};

export type LMSDashboardApi = BaseApi<
  LMSDashboardId,
  LMSDashboard,
  LMSDashboardGetAllFilter
>;

export const LMSDashboardClient = (client: HttpClient): LMSDashboardApi => ({
  ...baseApiFactory(client, 'dashboard'),
});
