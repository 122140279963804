import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { Project } from './models';

export type ProjectName = Pick<Project, 'name'>;

export type ProjectApi = {
  getAll: () => Promise<ListResponse<ProjectName>>;
};

export const ProjectClient = (client: HttpClient): ProjectApi => ({
  getAll: (): Promise<ListResponse<ProjectName>> =>
    client.get({ url: '/project' }),
});
