import React from 'react';
import { CoreAsyncButton, CoreTable } from '@amzn/dots-core-ui';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import { Device, useClient, useInfiniteGetAll } from '$/api';
/* eslint-disable import/no-cycle */
import { DeviceColumnDefinitions } from '$/features/Devices';

type HostDevicesTableProps = {
  host: string;
};

export const HostDevicesTable = ({
  host,
}: HostDevicesTableProps): JSX.Element => {
  const { devices } = useClient();
  const { data, allData, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteGetAll(devices.resourceKey, devices.getAll, { host });
  return (
    <Column spacing="small">
      <Heading level={3}>Devices</Heading>
      <CoreTable
        fixHeaderRows
        isLoading={isLoading && !data}
        columnDefinitions={DeviceColumnDefinitions}
        getItemId={(item: Device) => item.id}
        items={allData}
      />
      <Row alignmentHorizontal="center">
        <CoreAsyncButton
          disabled={!hasNextPage}
          type="tertiary"
          onClick={async (): Promise<void> => {
            await fetchNextPage();
          }}
        >
          Load More
        </CoreAsyncButton>
      </Row>
    </Column>
  );
};
