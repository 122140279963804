import { HttpClient } from '@amzn/dots-core-ui';
import { HardwareConfiguration } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type HardwareConfigurationId = HardwareConfiguration['id'];

export type HardwareConfigurationGetAllFilter =
  BaseGetAllFilter<HardwareConfigurationId> & {
    name?: string;
    deviceType?: string;
    platform?: string;
  };

export type HardwareConfigurationApi = BaseApi<
  HardwareConfigurationId,
  HardwareConfiguration,
  HardwareConfigurationGetAllFilter
>;

export const HardwareConfigurationClient = (
  client: HttpClient
): HardwareConfigurationApi => ({
  ...baseApiFactory(client, 'hardwareconfiguration'),
});
