import React from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import {
  CoreTextarea,
  CoreFormFooter,
  isNull,
  useForm,
  isNullOrWhitespace,
  CoreInput,
} from '@amzn/dots-core-ui';
import { Device, DeviceMutation, DevicePool } from '$/api';
import {
  DevicePoolSelect,
  DeviceTypeSelect,
  HardwareConfigurationSelect,
} from '$/components';

export type DeviceFormProps = {
  device?: Device;
  dsn?: string;
  onSubmit: (device: Partial<DeviceMutation>) => void;
  onCancel: () => void;
};

export const DeviceForm = ({
  dsn,
  device,
  onSubmit,
  onCancel,
}: DeviceFormProps): JSX.Element => {
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    dsn: {
      initialValue: device?.dsn ?? dsn ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value)
          ? 'DSN is required when registering a new device'
          : '',
    },
    type: {
      initialValue: device?.type?.id ? device.type : undefined,
      validate: (value): string =>
        isNull(value) ? 'Device Type is required' : '',
    },
    devicePools: {
      initialValue: device?.devicePools ?? [],
      validate: (value): string =>
        value.length ? '' : 'Devices must be in at least one device pool',
    },
    hardwareLabel: {
      initialValue: device?.hardwareConfiguration?.id
        ? device.hardwareConfiguration
        : undefined,
    },
    boardId: {
      initialValue: device?.boardId ?? '',
    },
    description: {
      initialValue: device?.description ?? '',
    },
    taskConfiguration: {
      initialValue: device?.taskConfig ?? '',
    },
  });

  return (
    <form
      onSubmit={handleSubmit((vs) => {
        const payload: Record<string, any> = {
          dsn: vs.dsn,
          typeId: vs.type.id,
          devicePoolIds: vs.devicePools.map(
            (pool: Partial<DevicePool>) => pool.id
          ),
          boardId: vs.boardId,
          description: vs.description?.trim(),
          taskConfig: vs.taskConfiguration?.trim(),
        };
        if (vs.hardwareLabel?.id) {
          payload.hardwareConfigurationId = vs.hardwareLabel.id;
        }
        return onSubmit(payload);
      })}
    >
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        {!device && !dsn && (
          <Alert type="error" title="Error!" size="large">
            {errors.dsn}
          </Alert>
        )}
        {!device && (
          <CoreInput
            disabled
            label="DSN"
            value={values.dsn}
            onChange={handlers.dsn}
            errorMessage={errors.dsn}
            validationState={states.dsn}
          />
        )}
        <DevicePoolSelect
          value={values.devicePools}
          onChange={handlers.devicePools}
          errorMessage={errors.devicePools}
          validationState={states.devicePools}
        />
        <DeviceTypeSelect
          value={values.type}
          onChange={handlers.type}
          errorMessage={errors.type}
          validationState={states.type}
        />
        <HardwareConfigurationSelect
          deviceType={values.type?.name}
          value={values.hardwareLabel}
          onChange={handlers.hardwareLabel}
          errorMessage={errors.hardwareLabel}
          validationState={states.hardwareLabel}
        />
        <CoreInput
          label="Board ID"
          value={values.boardId}
          onChange={handlers.boardId}
          errorMessage={errors.boardId}
          validationState={states.boardId}
        />
        <CoreTextarea
          label="Description"
          value={values.description}
          onChange={handlers.description}
          errorMessage={errors.description}
          validationState={states.description}
        />
        <CoreTextarea
          label="Task Configuration"
          value={values.taskConfiguration}
          onChange={handlers.taskConfiguration}
          errorMessage={errors.taskConfiguration}
          validationState={states.taskConfiguration}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};
