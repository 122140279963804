import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import { DevicePool } from '$/api';
import { PoolEditUri } from '$/features/routes';

export type PoolEditButtonProps = {
  pool: DevicePool;
};

export const PoolEditButton = ({ pool }: PoolEditButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip title={`edit ${pool.name}`} position="bottom">
      <Button
        type="tertiary"
        onClick={() => history.push(PoolEditUri(pool.id))}
      >
        <Icon tokens={pencilIconTokens} />
      </Button>
    </Tooltip>
  );
};
