import { HttpClient } from '@amzn/dots-core-ui';
import { LMSLab, LMSLocation } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type LMSLabId = LMSLab['id'];

export type LMSLabGetAllFilter = BaseGetAllFilter<LMSLabId> & {
  name?: string;
  description?: string;
  locationId?: LMSLocation['id'];
  lmslocation?: LMSLocation;
  labId?: number;
};

export type LMSLabApi = BaseApi<LMSLabId, LMSLab, LMSLabGetAllFilter>;

export const LMSLabClient = (client: HttpClient): LMSLabApi => ({
  ...baseApiFactory(client, 'lab'),
});
