import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import { LMSLab } from '$/api';
import { LabDetailUri } from '$/features/routes';

export const LabColumnDefinitions: ColumnDefinition<LMSLab>[] = [
  {
    id: 'lab',
    header: 'Lab Name',
    item: ({ id, name }): JSX.Element => (
      <CoreRouterLink href={LabDetailUri(id)}>{name}</CoreRouterLink>
    ),
  },
  {
    id: 'desc',
    header: 'Description',
    item: (lmslab): string => lmslab.description,
  },
  {
    id: 'locid',
    header: 'Location Id',
    item: (lmslab): number => lmslab.locationId,
  },
  {
    id: 'labid',
    header: 'Lab Id',
    item: (lmslab): number => lmslab.id,
  },
  /*
   * {
   *   TODO: Get number of hosts in lab
   *   id: 'hosts',
   *   header: 'Hosts',
   *   item: (hosts): number => hosts.
   * },
   * {
   *   TODO: Get number of devices in lab
   *   id: 'devices',
   *   header: 'Devices',
   *   item: (devices): number => devices.id,
   * },
   */
];
