import { useMemo } from 'react';
import { UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';
import { PaginatedResponse } from '@amzn/dots-core-ui';
import { BaseGetAllFilter, GetAllApi } from '../clients';

export const useInfiniteGetAll = <
  TId,
  TModel,
  TFilter extends BaseGetAllFilter<TId>
>(
  resourceKey: string,
  getAll: GetAllApi<TId, TModel, TFilter>,
  filters: TFilter
): UseInfiniteQueryResult<PaginatedResponse<TModel>, Error> & {
  allData: TModel[];
} => {
  const query = useInfiniteQuery<PaginatedResponse<TModel>, Error>(
    resourceKey,
    ({ pageParam }) => getAll({ ...filters, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.count === lastPage.pageSize ? lastPage.page + 1 : undefined,
    }
  );
  const allData = useMemo(
    () =>
      query.data?.pages?.reduce(
        (all, page) => all.concat(page.content),
        [] as TModel[]
      ) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.data?.pages]
  );

  return { ...query, allData };
};
