import { HttpClient, TokenCreator } from '@amzn/dots-core-ui';

import { LMSLabApi, LMSLabClient } from './lmslabs';
import { LMSLocationApi, LMSLocationClient } from './lmslocations';
import { LMSDashboardApi, LMSDashboardClient } from './lmsdashboards';

export * from './lmslabs';
export * from './lmslocations';
export * from './lmsdashboards';
export * from './models';

export interface LabDashboardApi {
  lmslabs: LMSLabApi;
  lmslocations: LMSLocationApi;
  dashboards: LMSDashboardApi;
}

export const createLabDashboardClient = (
  serverApiUrl: string,
  createToken: TokenCreator
): LabDashboardApi => {
  const client = new HttpClient(serverApiUrl, createToken);
  return {
    lmslabs: LMSLabClient(client),
    lmslocations: LMSLocationClient(client),
    dashboards: LMSDashboardClient(client),
  };
};
