import { useQueryString, useToaster } from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LMSLab, useClient, useCreate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { LabForm } from './components';
import { LabDetailUri, LabsUri, RootUri } from '$/features/routes';

export type LabQueryParams = {
  name: string;
  description: string;
  locationName: string;
};

export const LabAddPage = (): JSX.Element => {
  const { params } = useQueryString<LabQueryParams>({
    name: '',
    description: '',
    locationName: '',
  });

  const history = useHistory();
  const { openToast } = useToaster();
  const { lmslabs } = useClient();
  const { mutateAsync } = useCreate(lmslabs.resourceKey, lmslabs.create);
  const onSubmit = useCallback(
    async (lmslab: Partial<LMSLab>): Promise<void> => {
      const response = await mutateAsync(lmslab);
      openToast({
        title: 'Success!',
        message: `The ${response?.name} lab was registered`,
        type: 'success',
      });
      history.push(LabDetailUri(response.id));
    },
    [history, lmslabs, params, mutateAsync, openToast]
  );
  return (
    <CorePage
      title="Add Lab"
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Labs', href: LabsUri },
      ]}
      header={<CorePageTitle title="Add a New Lab" />}
    >
      <LabForm onSubmit={onSubmit} onCancel={() => history.push(LabsUri)} />
    </CorePage>
  );
};
