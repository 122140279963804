import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { DeleteApi } from '../clients';

export const useDelete = <TId>(
  resourceKey: string,
  deleteFunc: DeleteApi<TId>,
  optimistic = true
): UseMutationResult<void, Error, { id: TId }, (() => void) | undefined> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteFunc(id), {
    onMutate: ({ id }) => {
      const queryKeySingle = [resourceKey, { id }];
      const queryKeyCollection = [`${resourceKey}s`];
      queryClient.cancelQueries(queryKeySingle);
      queryClient.cancelQueries(queryKeyCollection);
      if (optimistic) {
        const previousSingle = queryClient.getQueryData(queryKeySingle);
        const previousCollection =
          queryClient.getQueryData<{ id: TId }[]>(queryKeyCollection);
        queryClient.removeQueries(queryKeySingle);
        queryClient.setQueryData(
          queryKeySingle,
          previousCollection?.filter((item) => item.id !== id)
        );
        return (): void => {
          queryClient.setQueryData(queryKeySingle, previousSingle);
          queryClient.setQueryData(queryKeyCollection, previousCollection);
        };
      }
      return undefined;
    },
    onSettled: (_, error, { id }, rollback) => {
      if (error && rollback) {
        rollback();
      }
      queryClient.invalidateQueries([resourceKey, { id }]);
      queryClient.invalidateQueries([`${resourceKey}s`]);
    },
  });
};
