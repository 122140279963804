import { useToaster } from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Host, useClient, useGet, useUpdate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
/* eslint-disable import/no-cycle */
import { HostForm } from './components';
import { HostDetailUri, HostsUri, RootUri } from '$/features/routes';

export const HostEditPage = (): JSX.Element => {
  const id = useIdParam();
  const history = useHistory();
  const { openToast } = useToaster();
  const { hosts } = useClient();
  const {
    data: host,
    error,
    isError,
    isLoading,
  } = useGet(hosts.resourceKey, hosts.get, id);
  const { mutateAsync } = useUpdate(hosts.resourceKey, hosts.update);
  const onSubmit = useCallback(
    async (item: Partial<Host>): Promise<void> => {
      await mutateAsync({ id, item });
      openToast({
        title: 'Success!',
        message: `${host?.name} has been updated.`,
        type: 'success',
      });
      history.push(HostDetailUri(id));
    },
    [mutateAsync, id, openToast, host, history]
  );
  return (
    <CorePage
      title="Edit"
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Host Agents', href: HostsUri },
        { title: host?.name ?? '', href: HostDetailUri(id) },
      ]}
      header={<CorePageTitle title={host ? `Edit ${host?.name}` : ''} />}
    >
      <HostForm
        onCancel={() => history.push(HostDetailUri(id))}
        onSubmit={onSubmit}
        host={host}
      />
    </CorePage>
  );
};
