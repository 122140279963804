import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToaster } from '@amzn/dots-core-ui';
import { useClient, useGet, useUpdate, DevicePool } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import { PoolForm } from './components';
import { PoolDetailUri, PoolsUri, RootUri } from '$/features/routes';

export const PoolEditPage = (): JSX.Element => {
  const id = useIdParam();
  const history = useHistory();
  const { openToast } = useToaster();
  const { devicePools } = useClient();
  const {
    data: pool,
    error,
    isError,
    isLoading,
  } = useGet(devicePools.resourceKey, devicePools.get, id);
  const { mutateAsync } = useUpdate(
    devicePools.resourceKey,
    devicePools.update
  );
  const onSubmit = useCallback(
    async (item: Partial<DevicePool>): Promise<void> => {
      await mutateAsync({ id, item });
      openToast({
        title: 'Success!',
        message: `${pool?.name} has been updated.`,
        type: 'success',
      });
      history.push(PoolDetailUri(id));
    },
    [mutateAsync, id, openToast, pool, history]
  );
  return (
    <CorePage
      title="Edit"
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'Scheduling', href: RootUri },
        { title: 'Device Pools', href: PoolsUri },
        { title: pool?.name ?? '', href: PoolDetailUri(id) },
      ]}
      header={<CorePageTitle title={pool ? `Edit ${pool?.name}` : ''} />}
    >
      <PoolForm
        pool={pool}
        onCancel={() => history.push(PoolDetailUri(id))}
        onSubmit={onSubmit}
      />
    </CorePage>
  );
};
