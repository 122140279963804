import React from 'react';
// import { CoreTabGroupSwitch, CoreTabRoute } from '@amzn/dots-core-ui';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { useClient, useGet } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import {
  //  PoolTestrunsTable,
  PoolEditButton,
  PoolDeleteButton,
  PoolDevicesTable,
  //  PoolAddTestrunButton,
  //  PoolRulesTable,
} from './components';
import { PoolsUri, RootUri } from '$/features/routes';

export const PoolDetailPage = (): JSX.Element => {
  const id = useIdParam();
  const { devicePools } = useClient();
  const {
    data: pool,
    error,
    isError,
    isLoading,
  } = useGet(devicePools.resourceKey, devicePools.get, id);

  return (
    <CorePage
      title={pool?.name}
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Device Pools', href: PoolsUri },
      ]}
      header={
        <>
          <Row widths={['fill', 'fit']}>
            <Column>
              {pool?.name && <CorePageTitle title={pool.name} />}
              <Column spacing="xxsmall">
                {pool?.description && (
                  <Text>Description: {pool.description}</Text>
                )}
                {pool?.ownerGroup && <Text>Owner: {pool.ownerGroup}</Text>}
                {pool?.createdBy && <Text>Created By: {pool.createdBy}</Text>}
              </Column>
            </Column>
            {pool && (
              <Row>
                <PoolDeleteButton pool={pool} />
                <PoolEditButton pool={pool} />
              </Row>
            )}
          </Row>
        </>
      }
    >
      {/*
    <CorePage
      title={pool?.name}
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'Scheduling', href: RootUri },
        { title: 'Device Pools', href: PoolsUri },
      ]}
      header={
        <Row widths={['fill', 'fit']}>
          <Column>
            {pool?.name && <CorePageTitle title={pool.name} />}
            <Column spacing="xxsmall">
              {pool?.description && (
                <Text>Description: {pool.description}</Text>
              )}
              {pool?.ownerGroup && <Text>Owner: {pool.ownerGroup}</Text>}
              {pool?.createdBy && <Text>Created By: {pool.createdBy}</Text>}
            </Column>
          </Column>
          {pool && (
            <Row>
              <PoolDeleteButton pool={pool} />
              <PoolEditButton pool={pool} />
              <PoolAddTestrunButton pool={pool} />
            </Row>
          )}
        </Row>
      }
    >
*/}
      {pool?.name && <PoolDevicesTable pool={pool?.name} />}

      {/*
      {pool && (
        <CoreTabGroupSwitch>
          <CoreTabRoute label="Devices" path="devices">
            <PoolDevicesTable pool={pool} />
          </CoreTabRoute>
          <CoreTabRoute label="Testruns" path="testruns">
            <PoolTestrunsTable pool={pool} />
          </CoreTabRoute>
          <CoreTabRoute label="Rules" path="rules">
            <PoolRulesTable pool={pool} />
          </CoreTabRoute>
        </CoreTabGroupSwitch>
      )}
      */}
    </CorePage>
  );
};
