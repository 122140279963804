import { HttpClient } from '@amzn/dots-core-ui';
import { Rule } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type RuleId = Rule['id'];

export type RuleGetAllFilter = BaseGetAllFilter<string> & {
  name?: string;
  owner?: string;
  pool?: string;
};

export interface RuleApi extends BaseApi<RuleId, Rule, RuleGetAllFilter> {
  copy: (id: RuleId, action: string, data: string) => Promise<Rule>;
  trigger: (id: RuleId) => Promise<void>;
}

const endpoint = 'rule';

export const RuleClient = (client: HttpClient): RuleApi => ({
  ...baseApiFactory(client, 'rule'),

  copy: (id: RuleId, action: string, data: string): Promise<Rule> =>
    client.post({
      url: `/${endpoint}/${id}/action`,
      payload: { action, data },
    }),

  trigger: (id: RuleId): Promise<void> =>
    client.put({ url: `/${endpoint}/trigger`, payload: { id } }),
});
