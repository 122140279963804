import React from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import {
  CoreTextarea,
  CoreFormFooter,
  useForm,
  CoreInput,
  isNullOrWhitespace,
} from '@amzn/dots-core-ui';
import { LMSLab, LMSLabMutation, useClient } from '$/api';
import { LMSLocationSelect } from '$/components';

export type LMSLabFormProps = {
  lab?: LMSLab;
  onCancel: () => void;
  onSubmit: (target: Partial<LMSLabMutation>) => Promise<void> | void;
};

export const LabForm = ({
  lab,
  onSubmit,
  onCancel,
}: LMSLabFormProps): JSX.Element => {
  const { lmslabs } = useClient();
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    name: {
      initialValue: lab?.name ?? '',
      validate: async (value: string): Promise<string> => {
        if (isNullOrWhitespace(value)) {
          return 'Name is required';
        }
        if (!/^[a-zA-Z0-9.\-_ ]+$/.test(value)) {
          return 'Name can only contain letters, numbers, spaces, and ._- characters';
        }
        if (value === lab?.name) {
          return '';
        }
        const result = await lmslabs.getAll({ name: value });
        if (result.count > 0) {
          return `${value} is already in use`;
        }
        return '';
      },
    },
    description: {
      initialValue: lab?.description ?? '',
    },
    location: {
      initialValue: lab?.location ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value) ? 'A location is required' : '',
    },
  });

  return (
    <form
      onSubmit={handleSubmit((vs) => {
        const payload: Record<string, any> = {
          name: vs.name,
          description: vs.description,
          locationId: vs.location.id,
        };

        return onSubmit(payload);
      })}
    >
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <CoreInput
          label="Name"
          value={values.name}
          onChange={handlers.name}
          errorMessage={errors.name}
          validationState={states.name}
        />
        <LMSLocationSelect
          value={values.location}
          onChange={handlers.location}
          errorMessage={errors.location}
          validationState={states.location}
        />
        <CoreTextarea
          label="Description"
          value={values.description}
          onChange={handlers.description}
          errorMessage={errors.description}
          validationState={states.description}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};
