import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import {
  Secret,
  SecretTarget,
  SecretTargetMutation,
  SecretTargetType,
} from './models';
import { BaseApi, baseApiFactory, BaseGetAllFilter } from '../baseApi';

export type SecretId = Secret['id'];
export type SecretTargetId = SecretTarget['id'];

export interface SecretGetAllFilter extends BaseGetAllFilter<SecretId> {
  name?: string;
  ownerGroup?: string;
  createdBy?: string;
  odinMatName?: string;
}

export interface SecretApi
  extends BaseApi<SecretId, Secret, SecretGetAllFilter> {
  getTargetTypes: () => Promise<ListResponse<SecretTargetType>>;
  getTargets: (secretId: SecretId) => Promise<ListResponse<SecretTarget>>;
  addTarget: (
    secretId: SecretId,
    target: SecretTargetMutation
  ) => Promise<SecretTarget>;
  removeTarget: (secretId: SecretId, targetId: number) => Promise<void>;
}

const endpoint = 'secret';

export const SecretClient = (client: HttpClient): SecretApi => ({
  ...baseApiFactory(client, endpoint),

  getTargetTypes: (): Promise<ListResponse<SecretTargetType>> =>
    client.get({ url: '/targettype' }),

  getTargets: (secretId: SecretId): Promise<ListResponse<SecretTarget>> =>
    client.get({
      url: `/${endpoint}/${secretId}/target`,
      payload: { page: 0, pageSize: 0 },
    }),

  addTarget: (
    secretId: SecretId,
    target: SecretTargetMutation
  ): Promise<SecretTarget> =>
    client.post({
      url: `/${endpoint}/${secretId}/target`,
      payload: target,
    }),

  removeTarget: (secretId: SecretId, targetId: SecretTargetId): Promise<void> =>
    client.delete({ url: `/${endpoint}/${secretId}/target/${targetId}` }),
});
