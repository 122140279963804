import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusIconTokens from '@amzn/meridian-tokens/base/icon/plus';
import { useHistory } from 'react-router-dom';
import { ButtonProps } from '@amzn/meridian/button/button';
import { HostAddUri } from '$/features/routes';
import { LMSLab } from '$/api';

export type HostAddButtonProps = {
  lmslab?: LMSLab;
  size?: ButtonProps['size'];
};

export const HostAddButton = ({
  lmslab,
  size = 'medium',
}: HostAddButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Button
      size={size}
      type={size === 'small' ? 'icon' : 'tertiary'}
      onClick={() => {
        history.push(HostAddUri(lmslab?.name ? lmslab?.name : ''));
      }}
    >
      <Icon tokens={plusIconTokens} />
      Add New
    </Button>
  );
};
