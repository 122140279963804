import { HttpClient, TokenCreator } from '@amzn/dots-core-ui';
import { BuildApi, BuildClient } from './builds';
import { DeviceApi, DeviceClient } from './devices';
import { DevicePoolApi, DevicePoolClient } from './devicePools';
import { DeviceTypeApi, DeviceTypeClient } from './deviceTypes';
import { GroupApi, GroupClient } from './groups';
import {
  HardwareConfigurationApi,
  HardwareConfigurationClient,
} from './hardwareConfigurations';
import { HostApi, HostClient } from './hosts';
import { LabApi, LabClient } from './labs';
import { LocationApi, LocationClient } from './locations';
import { ProjectApi, ProjectClient } from './projects';
import { SecretApi, SecretClient } from './secrets';
import { TestrunApi, TestrunClient } from './testruns';
import { WorkflowApi, WorkflowClient } from './workflows';
import { RuleApi, RuleClient } from './rules';

export * from './builds';
export * from './devices';
export * from './devicePools';
export * from './deviceTypes';
export * from './groups';
export * from './hardwareConfigurations';
export * from './hosts';
export * from './labs';
export * from './locations';
export * from './models';
export * from './projects';
export * from './secrets';
export * from './testruns';
export * from './workflows';
export * from './rules';

export interface SchedulingApi {
  builds: BuildApi;
  devices: DeviceApi;
  devicePools: DevicePoolApi;
  deviceTypes: DeviceTypeApi;
  groups: GroupApi;
  hardwareConfigurations: HardwareConfigurationApi;
  hosts: HostApi;
  labs: LabApi;
  locations: LocationApi;
  projects: ProjectApi;
  secrets: SecretApi;
  testruns: TestrunApi;
  workflows: WorkflowApi;
  rules: RuleApi;
}

export const createSchedulingClient = (
  serverApiUrl: string,
  createToken: TokenCreator
): SchedulingApi => {
  const client = new HttpClient(serverApiUrl, createToken);
  return {
    builds: BuildClient(client),
    devices: DeviceClient(client),
    devicePools: DevicePoolClient(client),
    deviceTypes: DeviceTypeClient(client),
    groups: GroupClient(client),
    hardwareConfigurations: HardwareConfigurationClient(client),
    hosts: HostClient(client),
    labs: LabClient(client),
    locations: LocationClient(client),
    projects: ProjectClient(client),
    secrets: SecretClient(client),
    testruns: TestrunClient(client),
    workflows: WorkflowClient(client),
    rules: RuleClient(client),
  };
};
