import { useQueryString, useToaster } from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LMSDashboard, useClient, useCreate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { DashboardForm } from './components';
import { DashboardDetailUri, DashboardsUri, RootUri } from '$/features/routes';

export type DashboardQueryParams = {
  hostname: string;
  publicKey: string;
  version: string;
};

export const DashboardAddPage = (): JSX.Element => {
  const { params } = useQueryString<DashboardQueryParams>({
    hostname: '',
    publicKey: '',
    version: '',
  });
  const history = useHistory();
  const { openToast } = useToaster();
  const { dashboards } = useClient();
  const { mutateAsync } = useCreate(dashboards.resourceKey, dashboards.create);
  const onSubmit = useCallback(
    async (dashboard: Partial<LMSDashboard>): Promise<void> => {
      const response = await mutateAsync(dashboard);
      openToast({
        title: 'Success!',
        message: `The ${response?.name} dashboard was registered`,
        type: 'success',
      });
      history.push(DashboardDetailUri(response.id));
    },
    [history, dashboards, params, mutateAsync, openToast]
  );
  return (
    <CorePage
      title="Register Agent"
      breadcrumbs={[
        { title: 'LMS Lab LMSDashboard', href: RootUri },
        { title: 'Dashboards', href: DashboardsUri },
      ]}
      header={<CorePageTitle title="Register a New LMSDashboard Agent" />}
    >
      <DashboardForm
        onSubmit={onSubmit}
        onCancel={() => history.push(DashboardsUri)}
        hostname={params.hostname}
        rsapub={params.publicKey}
        version={params.version}
      />
    </CorePage>
  );
};
