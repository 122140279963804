import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import Tooltip from '@amzn/meridian/tooltip';
import { LMSDashboard } from '$/api';
import { DashboardEditUri } from '$/features/routes';

export type DashboardEditButtonProps = {
  dashboard: LMSDashboard;
};

export const DashboardEditButton = ({
  dashboard,
}: DashboardEditButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip title={`Edit ${dashboard.name}`} position="bottom">
      <Button
        type="tertiary"
        onClick={() => history.push(DashboardEditUri(dashboard.id))}
      >
        <Icon tokens={pencilIconTokens} />
      </Button>
    </Tooltip>
  );
};
