import React from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';

import { useClient, useGetAll } from '$/api';
import { CorePage, CorePageTitle, CoreSearchTable } from '$/components';
import { DeviceColumnDefinitions } from './components';
import { RootUri } from '$/features/routes';

const filters = [
  { key: 'dsn', label: 'DSN', isDefault: true },
  { key: 'devicePoolName', label: 'Device Pool' },
  { key: 'createdBy', label: 'Created By' },
  { key: 'type', label: 'Type' },
  { key: 'host', label: 'Host' },
  { key: 'status', label: 'Status' },
];

export const DeviceListPage = (): JSX.Element => {
  const { devices } = useClient();
  const { data, isLoading } = useGetAll(
    devices.resourceKey,
    devices.getAll,
    {}
  );
  return (
    <CorePage
      title="Devices"
      breadcrumbs={[{ title: 'LMS Lab Dashboard', href: RootUri }]}
      header={
        <>
          <Row widths={['fill', 'fit']}>
            <Column>
              <CorePageTitle title="Devices" />
              <Column spacing="xxsmall">
                <Text>
                  children={!isLoading ? `Total: ${data?.total}` : ''}
                </Text>
              </Column>
            </Column>
          </Row>
        </>
      }
    >
      <CoreSearchTable
        resourceKey={devices.resourceKey}
        getAll={devices.getAll}
        resourceFilters={filters}
        columns={DeviceColumnDefinitions}
      />
    </CorePage>
  );
};
