import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { CreateApi } from '../clients';

export const useCreate = <TModel, TModelPayload = TModel>(
  resourceKey: string,
  create: CreateApi<TModel, TModelPayload>
): UseMutationResult<TModel, Error, TModelPayload, () => void> => {
  const queryClient = useQueryClient();
  return useMutation(create, {
    onSuccess: () => queryClient.invalidateQueries([`${resourceKey}s`]),
  });
};
