import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { Group } from './models';

export type GroupApi = {
  getAll: () => Promise<ListResponse<Group>>;
};

export const GroupClient = (client: HttpClient): GroupApi => ({
  getAll: (): Promise<ListResponse<Group>> => client.get({ url: '/group' }),
});
