import { useToaster } from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LMSLocation, useClient, useGet, useUpdate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import { LocationForm } from './components';
import { LocationDetailUri, LocationsUri, RootUri } from '$/features/routes';

export const LocationEditPage = (): JSX.Element => {
  const id = useIdParam();
  const history = useHistory();
  const { openToast } = useToaster();
  const { lmslocations } = useClient();
  const {
    data: lmslocation,
    error,
    isError,
    isLoading,
  } = useGet(lmslocations.resourceKey, lmslocations.get, id);
  const { mutateAsync } = useUpdate(
    lmslocations.resourceKey,
    lmslocations.update
  );
  const onSubmit = useCallback(
    async (item: Partial<LMSLocation>): Promise<void> => {
      await mutateAsync({ id, item });
      openToast({
        title: 'Success!',
        message: `${lmslocation?.name} has been updated.`,
        type: 'success',
      });
      history.push(LocationDetailUri(id));
    },
    [mutateAsync, id, openToast, lmslocation, history]
  );
  return (
    <CorePage
      title="Edit"
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Locations', href: LocationsUri },
        { title: lmslocation?.name ?? '', href: LocationDetailUri(id) },
      ]}
      header={
        <CorePageTitle title={lmslocation ? `Edit ${lmslocation?.name}` : ''} />
      }
    >
      <LocationForm
        onCancel={() => history.push(LocationDetailUri(id))}
        onSubmit={onSubmit}
        lmslocation={lmslocation}
      />
    </CorePage>
  );
};
