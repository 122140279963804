import React from 'react';

import { useClient } from '$/api';
import { CorePage, CorePageTitle, CoreSearchTable } from '$/components';
import { DashboardColumnDefinitions } from './components';
import { RootUri } from '$/features/routes';

const filters = [
  { key: 'name', label: 'Name', isDefault: true },
  { key: 'owner', label: 'Owner' },
  { key: 'status', label: 'Status' },
  { key: 'createdBy', label: 'Created By' },
  { key: 'lab', label: 'Lab' },
  { key: 'location', label: 'Location' },
  { key: 'version', label: 'Version' },
];

export const DashboardListPage = (): JSX.Element => {
  const { dashboards } = useClient();
  /* eslint-disable prettier/prettier */
  return (
    <CorePage
      title="Dashboards"
      header={<CorePageTitle title="Dashboards" />}
      breadcrumbs={[{ title: 'LMS Lab Dashboard', href: RootUri }]}
    >
      <CoreSearchTable
        resourceKey={dashboards.resourceKey}
        getAll={dashboards.getAll}
        resourceFilters={filters}
        columns={DashboardColumnDefinitions}
      />
    </CorePage>
  );
};
