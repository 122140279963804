/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { isNullOrWhitespace, ValidationProps } from '@amzn/dots-core-ui';
import { useClient, DeviceType } from '$/api';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';

export type DeviceTypeSelectProps = Pick<
  ValidationProps<CoreAsyncSelectProps<DeviceType>>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export const DeviceTypeSelect = (props: DeviceTypeSelectProps): JSX.Element => {
  const { deviceTypes } = useClient();
  const getTypes = async (query: string): Promise<DeviceType[]> => {
    if (isNullOrWhitespace(query)) {
      return [];
    }
    const { content } = await deviceTypes.getAll({
      name: query,
    });
    return content;
  };
  return (
    <CoreAsyncSelect
      {...props}
      label="Device Type"
      getItems={getTypes}
      getItemLabel={(item): string => item.name}
      getItemValue={(item): number => item.id}
    />
  );
};
