import React from 'react';
import Row from '@amzn/meridian/row';
import { useClient } from '$/api';
import { CorePage, CorePageTitle, CoreSearchTable } from '$/components';
import { PoolAddButton, PoolColumnDefinitions } from './components';
import { RootUri } from '$/features/routes';

const filters = [
  {
    key: 'name',
    label: 'Name',
    isDefault: true,
  },
  {
    key: 'createdBy',
    label: 'Created By',
  },
  {
    key: 'ownerGroup',
    label: 'Owner',
  },
];

export const PoolListPage = (): JSX.Element => {
  const { devicePools } = useClient();
  return (
    <CorePage
      title="Device Pools"
      breadcrumbs={[{ title: 'Scheduling', href: RootUri }]}
      header={
        <Row widths="fill">
          <CorePageTitle title="Device Pools" />
          <Row alignmentHorizontal="right">
            <PoolAddButton />
          </Row>
        </Row>
      }
    >
      <CoreSearchTable
        resourceKey={devicePools.resourceKey}
        getAll={devicePools.getAll}
        resourceFilters={filters}
        columns={PoolColumnDefinitions}
      />
    </CorePage>
  );
};
