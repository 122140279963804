import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryString, useToaster } from '@amzn/dots-core-ui';
import { useClient, useCreate, DeviceMutation } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { RootUri, DevicesUri, DeviceDetailUri } from '$/features/routes';
import { DeviceForm } from './components';

export const DeviceAddPage = (): JSX.Element => {
  const { params } = useQueryString<{ dsn: string | undefined }>({
    dsn: undefined,
  });
  const history = useHistory();
  const { openToast } = useToaster();
  const { devices } = useClient();
  const { mutateAsync } = useCreate(devices.resourceKey, devices.create);
  const onSubmit = useCallback(
    async (device: Partial<DeviceMutation>): Promise<void> => {
      const response = await mutateAsync(device);
      openToast({
        title: 'Success!',
        message: `The ${response?.dsn} device was registered`,
        type: 'success',
      });
      history.push(DeviceDetailUri(response.id));
    },
    [history, mutateAsync, openToast]
  );
  return (
    <CorePage
      title="Register"
      breadcrumbs={[
        { title: 'LMS Lab Dashboard', href: RootUri },
        { title: 'Devices', href: DevicesUri },
      ]}
      header={<CorePageTitle title="Register a New Device" />}
    >
      <DeviceForm
        dsn={params.dsn}
        onCancel={() => history.push(DevicesUri)}
        onSubmit={onSubmit}
      />
    </CorePage>
  );
};
