import { UseQueryResult, useQuery, UseQueryOptions } from 'react-query';
import { GetApi } from '../clients';

export const useGet = <TId, TModel>(
  resourceKey: string,
  get: GetApi<TId, TModel>,
  id: TId,
  options?: UseQueryOptions<unknown, Error, TModel>
): UseQueryResult<TModel, Error> =>
  useQuery([resourceKey, { id }], () => get(id), options);
