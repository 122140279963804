import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import { LMSLocation } from '$/api';
import { LocationDetailUri } from '$/features/routes';

export const LocationColumnDefinitions: ColumnDefinition<LMSLocation>[] = [
  {
    id: 'location',
    header: 'Location',
    item: ({ id, name }): JSX.Element => (
      <CoreRouterLink href={LocationDetailUri(id)}>{name}</CoreRouterLink>
    ),
  },
  {
    id: 'desc',
    header: 'Description',
    item: (lmslocation): string => lmslocation.description,
  },
  {
    id: 'id',
    header: 'ID',
    item: (lmslocation): number => lmslocation.id,
  },
];
