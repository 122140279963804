import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import trashIconTokens from '@amzn/meridian-tokens/base/icon/trash';
import orangeLightTokens from '@amzn/meridian-tokens/theme/orange-light';
import { DevicePool, useClient, useDelete } from '$/api';
import { PoolsUri } from '$/features/routes';

export type PoolDeleteButtonProps = {
  pool: DevicePool;
};

export const PoolDeleteButton = ({
  pool,
}: PoolDeleteButtonProps): JSX.Element => {
  const history = useHistory();
  const { openToast } = useToaster();
  const { devicePools } = useClient();
  const { mutateAsync } = useDelete(
    devicePools.resourceKey,
    devicePools.delete
  );
  const onClick = useCallback(async (): Promise<void> => {
    //  const response = await mutateAsync({ id: pool.id });
    // console.log(response);
    openToast({
      title: 'Success!',
      message: `${pool.name} has been deleted.`,
      type: 'success',
    });
    history.push(PoolsUri);
  }, [mutateAsync, pool.id, pool.name, openToast, history]);
  return (
    <Theme tokens={orangeLightTokens}>
      <Tooltip title={`remove ${pool.name}`} position="bottom">
        <CoreAsyncButton
          type="tertiary"
          onClick={onClick}
          confirm={{
            title: `Remove ${pool.name}`,
            children: (
              <Text>
                Are you sure you want to delete <strong>{pool.name}</strong>?
              </Text>
            ),
          }}
        >
          <Icon tokens={trashIconTokens} />
        </CoreAsyncButton>
      </Tooltip>
    </Theme>
  );
};
