import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SideMenu, {
  SideMenuTitle,
  SideMenuLink,
} from '@amzn/meridian/side-menu';
import Heading from '@amzn/meridian/heading';
import {
  //  DashboardsUri,
  LocationsUri,
  LabsUri,
  PoolsUri,
  HostsUri,
  DevicesUri,
} from './routes';

export const AppNavigation = ({
  isMenuOpen,
}: {
  isMenuOpen: boolean;
}): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const pathStartsWith = (test: string): boolean =>
    location.pathname.substring(0, test.length) === test;
  return (
    <SideMenu width={225} open={isMenuOpen}>
      <SideMenuTitle href={LocationsUri} onClick={history.push}>
        <Heading level={4}>LMS Lab Dashboard</Heading>
      </SideMenuTitle>
      {[
        //  { title: 'Dashboards', path: DashboardsUri },
        { title: 'Locations', path: LocationsUri },
        { title: 'Labs', path: LabsUri },
        { title: 'Pools', path: PoolsUri },
        { title: 'Hosts', path: HostsUri },
        { title: 'Devices', path: DevicesUri },
      ].map((item) => (
        <SideMenuLink
          key={item.title}
          href={item.path}
          onClick={history.push}
          selected={pathStartsWith(item.path)}
        >
          {item.title}
        </SideMenuLink>
      ))}
    </SideMenu>
  );
};
