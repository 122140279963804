import { HttpClient } from '@amzn/dots-core-ui';
import { Workflow } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type WorkflowId = Workflow['id'];

export type WorkflowGetAllFilter = BaseGetAllFilter<WorkflowId>;

export type WorkflowApi = BaseApi<WorkflowId, Workflow, WorkflowGetAllFilter>;

export const WorkflowClient = (client: HttpClient): WorkflowApi => ({
  ...baseApiFactory(client, 'workflow'),
});
