/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { isNullOrWhitespace, ValidationProps } from '@amzn/dots-core-ui';
import { useClient, HardwareConfiguration } from '$/api';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';

export type HardwareConfigurationSelectProps = Pick<
  ValidationProps<CoreAsyncSelectProps<HardwareConfiguration>>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
> & {
  deviceType: string;
};

export const HardwareConfigurationSelect = (
  props: HardwareConfigurationSelectProps
): JSX.Element => {
  const { deviceType, ...restProps } = props;
  const { hardwareConfigurations } = useClient();
  const getTypes = async (query: string): Promise<HardwareConfiguration[]> => {
    if (isNullOrWhitespace(query)) {
      return [];
    }
    const { content } = await hardwareConfigurations.getAll({
      name: query,
      deviceType,
    });
    return content;
  };
  return (
    <CoreAsyncSelect
      {...restProps}
      label="Hardware Label"
      getItems={getTypes}
      getItemLabel={(item): string => item.name}
      getItemValue={(item): number => item.id}
    />
  );
};
