import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CoreToasterProvider } from '@amzn/dots-core-ui';
import { Home } from './Home';
import {
  RootUri,
  DashboardsUri,
  LocationsUri,
  LabsUri,
  HostsUri,
  PoolsUri,
  DevicesUri,
} from './routes';

import {
  DashboardAddPage,
  DashboardDetailPage,
  DashboardEditPage,
  DashboardListPage,
} from './Dashboards';

import {
  LocationAddPage,
  LocationDetailPage,
  LocationEditPage,
  LocationListPage,
} from './Locations';

import { LabAddPage, LabDetailPage, LabEditPage, LabListPage } from './Labs';

import {
  HostAddPage,
  HostDetailPage,
  HostEditPage,
  HostListPage,
} from './Hosts';

import {
  DeviceAddPage,
  DeviceDetailPage,
  DeviceEditPage,
  DeviceListPage,
} from './Devices';

import {
  PoolAddPage,
  PoolDetailPage,
  PoolEditPage,
  PoolListPage,
} from './Pools';

export type AppRouterProps = {
  isRootApp: boolean;
};

export const AppRouter = ({ isRootApp }: AppRouterProps): JSX.Element => {
  return (
    <CoreToasterProvider>
      <Switch>
        <Route path={`${DashboardsUri}/add`}>
          <DashboardAddPage />
        </Route>
        <Route path={`${DashboardsUri}/:id/edit`}>
          <DashboardEditPage />
        </Route>
        <Route path={`${DashboardsUri}/:id`}>
          <DashboardDetailPage />
        </Route>
        <Route path={`${DashboardsUri}`}>
          <DashboardListPage />
        </Route>

        <Route path={`${LocationsUri}/add`}>
          <LocationAddPage />
        </Route>
        <Route path={`${LocationsUri}/:id/edit`}>
          <LocationEditPage />
        </Route>
        <Route path={`${LocationsUri}/:id`}>
          <LocationDetailPage />
        </Route>
        <Route path={`${LocationsUri}`}>
          <LocationListPage />
        </Route>

        <Route path={`${LabsUri}/add`}>
          <LabAddPage />
        </Route>
        <Route path={`${LabsUri}/:id/edit`}>
          <LabEditPage />
        </Route>
        <Route path={`${LabsUri}/:id`}>
          <LabDetailPage />
        </Route>
        <Route path={`${LabsUri}`}>
          <LabListPage />
        </Route>

        <Route path={`${HostsUri}/add`}>
          <HostAddPage />
        </Route>
        <Route path={`${HostsUri}/:id/edit`}>
          <HostEditPage />
        </Route>
        <Route path={`${HostsUri}/:id`}>
          <HostDetailPage />
        </Route>
        <Route path={`${HostsUri}`}>
          <HostListPage />
        </Route>

        <Route path={`${PoolsUri}/add`}>
          <PoolAddPage />
        </Route>
        <Route path={`${PoolsUri}/:id/edit`}>
          <PoolEditPage />
        </Route>
        <Route path={`${PoolsUri}/:id`}>
          <PoolDetailPage />
        </Route>
        <Route path={`${PoolsUri}`}>
          <PoolListPage />
        </Route>

        <Route path={`${DevicesUri}/register`}>
          <DeviceAddPage />
        </Route>
        <Route path={`${DevicesUri}/:id/edit`}>
          <DeviceEditPage />
        </Route>
        <Route path={`${DevicesUri}/:id`}>
          <DeviceDetailPage />
        </Route>
        <Route path={`${DevicesUri}`}>
          <DeviceListPage />
        </Route>

        <Route path={`${RootUri}`}>
          <Home />
        </Route>

        {isRootApp && <Redirect to={`${LocationsUri}`} />}
      </Switch>
    </CoreToasterProvider>
  );
};
