import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { Location } from './models';

export type LocationApi = {
  getAll: () => Promise<ListResponse<Location>>;
};

export const LocationClient = (client: HttpClient): LocationApi => ({
  getAll: (): Promise<ListResponse<Location>> =>
    client.get({ url: '/location' }),
});
