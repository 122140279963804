import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import { DevicePool } from '$/api';
import { PoolDetailUri } from '$/features/routes';

export const PoolColumnDefinitions: ColumnDefinition<DevicePool>[] = [
  {
    id: 'link',
    header: 'Name',
    item: ({ id, name }): JSX.Element => (
      <CoreRouterLink href={PoolDetailUri(id)}>{name}</CoreRouterLink>
    ),
  },
  {
    id: 'createdby',
    header: 'Created By',
    item: (pool): string => pool.createdBy ?? 'no owner',
  },
  {
    id: 'owner',
    header: 'Owner',
    item: (pool): string => pool.ownerGroup ?? 'no owner',
  },
];
