import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import { Device } from '$/api';
import { CoreDateFromNow } from '$/components';
import { DeviceStatusLabel } from './DeviceStatusLabel';
import { DeviceDetailUri, PoolDetailUri } from '$/features/routes';

export const DeviceColumnDefinitions: ColumnDefinition<Device>[] = [
  {
    id: 'dsn',
    header: 'DSN',
    item: ({ id, dsn }): JSX.Element => (
      <CoreRouterLink href={DeviceDetailUri(id)}>{dsn}</CoreRouterLink>
    ),
  },
  {
    id: 'status',
    header: 'Status',
    item: ({ status, statusModified }): JSX.Element => (
      <Column spacing="small">
        <DeviceStatusLabel status={status} />
        {statusModified && <CoreDateFromNow date={statusModified} />}
      </Column>
    ),
  },
  {
    id: 'type',
    header: 'Type',
    item: ({ type, build, hardwareConfiguration }): JSX.Element => (
      <Column spacing="small">
        <Text>
          <strong>{type?.name}</strong>
          <Text>{hardwareConfiguration?.name}</Text>
        </Text>
        {build?.type?.project?.name && (
          <Text>{build?.type?.project?.name}</Text>
        )}
      </Column>
    ),
  },
  {
    id: 'pool',
    header: 'Pool',
    item: ({ devicePools }): JSX.Element => (
      <Column spacing="small">
        {devicePools?.map((pool) => (
          <CoreRouterLink key={pool.id} href={PoolDetailUri(pool?.id ?? '')}>
            {pool.name}
          </CoreRouterLink>
        ))}
      </Column>
    ),
  },
  {
    id: 'owner',
    header: 'Owner',
    item: (device): string => device.createdBy ?? 'no one',
  },

  {
    id: 'id',
    header: 'ID',
    item: (device): number => device.id,
  },
];
