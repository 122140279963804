import React, { useCallback } from 'react';
import { useToaster } from '@amzn/dots-core-ui';
import { useHistory } from 'react-router-dom';
import { useClient, useCreate, DevicePool } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { PoolForm } from './components';
import { PoolDetailUri, PoolsUri, RootUri } from '$/features/routes';

export const PoolAddPage = (): JSX.Element => {
  const history = useHistory();
  const { openToast } = useToaster();
  const { devicePools } = useClient();
  const { mutateAsync } = useCreate(
    devicePools.resourceKey,
    devicePools.create
  );
  const onSubmit = useCallback(
    async (pool: Partial<DevicePool>): Promise<void> => {
      const response = await mutateAsync(pool);
      openToast({
        title: 'Success!',
        message: `The ${response?.name} device pool was created`,
        type: 'success',
      });
      history.push(PoolDetailUri(response.id));
    },
    [history, mutateAsync, openToast]
  );
  return (
    <CorePage
      title="Add New"
      breadcrumbs={[
        { title: 'Scheduling', href: RootUri },
        { title: 'Device Pools', href: PoolsUri },
      ]}
      header={<CorePageTitle title="Add a New Device Pool" />}
    >
      <PoolForm onCancel={() => history.push(PoolsUri)} onSubmit={onSubmit} />
    </CorePage>
  );
};
