import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import Tooltip from '@amzn/meridian/tooltip';
import { LMSLab } from '$/api';
import { LabEditUri } from '$/features/routes';

export type LabEditButtonProps = {
  lmslab: LMSLab;
};

export const LabEditButton = ({ lmslab }: LabEditButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip title={`Edit ${lmslab.name}`} position="bottom">
      <Button
        type="tertiary"
        onClick={() => history.push(LabEditUri(lmslab.id))}
      >
        <Icon tokens={pencilIconTokens} />
      </Button>
    </Tooltip>
  );
};
