import { HttpClient } from '@amzn/dots-core-ui';
import { Build } from './models';
import { BaseGetAllFilter, BaseReadApi, baseReadApiFactory } from '../baseApi';

export type BuildId = Build['id'];

export type BuildGetAllFilter = BaseGetAllFilter<BuildId>;

export type BuildApi = BaseReadApi<BuildId, Build, BuildGetAllFilter>;

export const BuildClient = (client: HttpClient): BuildApi => ({
  ...baseReadApiFactory(client, 'build'),
});
