import { HttpClient } from '@amzn/dots-core-ui';
import { DevicePool } from './models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type DevicePoolId = DevicePool['id'];

export type DevicePoolGetAllFilter = BaseGetAllFilter<number> & {
  name?: string;
  createdBy?: string;
  ownerGroup?: string;
};

export type DevicePoolApi = BaseApi<number, DevicePool, DevicePoolGetAllFilter>;

export const DevicePoolClient = (client: HttpClient): DevicePoolApi => ({
  ...baseApiFactory(client, 'devicepool'),
});
