/* eslint-disable react/jsx-props-no-spreading */
//  import React, { useMemo } from 'react';

import React from 'react';
import {
  //  CoreFilterableSelect,
  //  CoreAsyncSelectProps,
  ValidationProps,
  isNullOrWhitespace,
} from '@amzn/dots-core-ui';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';
//  import { useQuery } from 'react-query';
//  import Alert from '@amzn/meridian/alert';
import { useClient, LMSLocation } from '$/api';

export type LMSLocationSelectProps = Pick<
  ValidationProps<CoreAsyncSelectProps<LMSLocation>>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export const LMSLocationSelect = (
  props: LMSLocationSelectProps
): JSX.Element => {
  const { lmslocations } = useClient();
  const getTypes = async (query: string): Promise<LMSLocation[]> => {
    if (isNullOrWhitespace(query)) {
      return [];
    }
    const { content } = await lmslocations.getAll({
      name: query,
      fuzzySearch: true,
    });
    return content;
  };
  return (
    <CoreAsyncSelect
      {...props}
      label="Location"
      getItems={getTypes}
      getItemLabel={(item): string => item.name}
      getItemValue={(item): number => item.id}
    />
  );
};

/*
export const LMSLocationSelect = ({
  validationState,
  ...rest
}: LMSLocationSelectProps): JSX.Element => {
  const { lmslocations } = useClient();

  const { data, isLoading, isError, error } = useQuery<string[], Error>(
    'lmslocations',
    async () => {
      const response = await lmslocations.getAll({ page: 0, pageSize: 0 });
      return response.content.map((item) => item.name);
    }
  );

  

  const items = useMemo(() => data ?? [], [data]);
  if (isError) {
    return (
      <Alert type="error" title="Failed to get locations">
        {error?.message}
      </Alert>
    );
  }
  return (
    <CoreFilterableSelect
      {...rest}
      label="Location"
      items={items}
      disabled={isLoading}
      validationState={isLoading ? 'validating' : validationState}
    />
  );

};
*/
