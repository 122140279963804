import React from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';

import { useClient } from '$/api';
import { CorePage, CorePageTitle, CoreSearchTable } from '$/components';
import { LabAddButton, LabColumnDefinitions } from './components';
import { RootUri } from '$/features/routes';

const filters = [
  { key: 'name', label: 'Name', isDefault: true },
  { key: 'description', label: 'Description' },
];

export const LabListPage = (): JSX.Element => {
  const { lmslabs } = useClient();
  return (
    <CorePage
      title="Labs"
      breadcrumbs={[{ title: 'LMS Lab Dashboard', href: RootUri }]}
      header={
        <>
          <Row widths={['fill', 'fit']}>
            <Column>
              <CorePageTitle title="Labs" />
            </Column>
            <Row alignmentHorizontal="right">
              <LabAddButton />
            </Row>
          </Row>
        </>
      }
    >
      <CoreSearchTable
        resourceKey={lmslabs.resourceKey}
        getAll={lmslabs.getAll}
        resourceFilters={filters}
        columns={LabColumnDefinitions}
      />
    </CorePage>
  );
};
