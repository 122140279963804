import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusIconTokens from '@amzn/meridian-tokens/base/icon/plus';
import { PoolAddUri } from '$/features/routes';

export const PoolAddButton = (): JSX.Element => {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(PoolAddUri)}>
      <Icon tokens={plusIconTokens} />
      Add New
    </Button>
  );
};
