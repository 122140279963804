import React from 'react';
import { CoreAsyncButton, CoreTable } from '@amzn/dots-core-ui';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import { Host, useClient, useInfiniteGetAll } from '$/api';
import { HostColumnDefinitions } from '$/features/Hosts';

type LabHostsTableProps = {
  labId: number;
};

export const LabHostsTable = ({ labId }: LabHostsTableProps): JSX.Element => {
  const { hosts } = useClient();
  const { data, allData, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteGetAll(hosts.resourceKey, hosts.getAll, { labId });
  return (
    <Column spacing="small">
      <Heading level={3}>Hosts</Heading>
      <CoreTable
        fixHeaderRows
        isLoading={isLoading && !data}
        columnDefinitions={HostColumnDefinitions}
        getItemId={(item: Host) => item.id}
        items={allData}
      />
      <Row alignmentHorizontal="center">
        <CoreAsyncButton
          disabled={!hasNextPage}
          type="tertiary"
          onClick={async (): Promise<void> => {
            await fetchNextPage();
          }}
        >
          Load More
        </CoreAsyncButton>
      </Row>
    </Column>
  );
};
