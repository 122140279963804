import { HttpClient } from '@amzn/dots-core-ui';
import { Plan } from './models';
import { BaseGetAllFilter, BaseReadApi, baseReadApiFactory } from '../baseApi';

export type PlanId = Plan['id'];

export interface PlanGetAllFilter extends BaseGetAllFilter<PlanId> {
  ids?: number[];
  name?: string;
  page?: number;
  pageSize?: number;
}

export type PlanApi = BaseReadApi<PlanId, Plan, PlanGetAllFilter>;

export const PlanClient = (client: HttpClient): PlanApi => ({
  ...baseReadApiFactory(client, 'plan'),
});
