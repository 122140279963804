/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ColorTokens from '@amzn/meridian-tokens/base/color';
import SpacingTokens from '@amzn/meridian-tokens/base/spacing';
import Text from '@amzn/meridian/text';
import { borderRoundedRadius } from '@amzn/meridian-tokens/base/border';
import { TextPropsWithRef } from '@amzn/meridian/text/text';
import { css } from 'emotion';

export type CoreLabelColor =
  | 'darkPurple'
  | 'lightPurple'
  | 'darkGray'
  | 'lightGray'
  | 'darkOrange'
  | 'lightOrange'
  | 'tangerine'
  | 'yellow'
  | 'red'
  | 'blue'
  | 'green';

type TextColor = {
  backgroundColor: string;
  color: string;
};

type ColorMap = {
  [k in CoreLabelColor]: TextColor;
};

const colorMap: ColorMap = {
  darkPurple: { backgroundColor: '#3c0078', color: ColorTokens.colorGray0 },
  lightPurple: {
    backgroundColor: ColorTokens.colorPurple500,
    color: ColorTokens.colorGray0,
  },
  darkGray: {
    backgroundColor: ColorTokens.colorGray600,
    color: ColorTokens.colorGray0,
  },
  lightGray: {
    backgroundColor: ColorTokens.colorGray300,
    color: ColorTokens.colorSquidInk,
  },
  darkOrange: {
    backgroundColor: ColorTokens.colorOrange500,
    color: ColorTokens.colorGray0,
  },
  lightOrange: {
    backgroundColor: ColorTokens.colorOrange400,
    color: ColorTokens.colorSquidInk,
  },
  tangerine: {
    backgroundColor: ColorTokens.colorTangerine400,
    color: ColorTokens.colorSquidInk,
  },
  yellow: {
    backgroundColor: ColorTokens.colorYellow400,
    color: ColorTokens.colorSquidInk,
  },
  red: {
    backgroundColor: ColorTokens.colorRed400,
    color: ColorTokens.colorGray0,
  },
  blue: {
    backgroundColor: ColorTokens.colorBlue400,
    color: ColorTokens.colorSquidInk,
  },
  green: {
    backgroundColor: ColorTokens.colorGreen500,
    color: ColorTokens.colorSquidInk,
  },
};

const style = ({ color, backgroundColor }: TextColor): string =>
  css({
    color,
    backgroundColor,
    margin: 0,
    display: 'inline-block',
    borderRadius: borderRoundedRadius,
    whiteSpace: 'pre',
    padding: `${SpacingTokens.spacing100}px ${SpacingTokens.spacing300}px`,
  });

export type CoreLabelProps = {
  color?: CoreLabelColor;
  children: React.ReactNode;
} & Pick<TextPropsWithRef, 'fontFamily' | 'transform' | 'type'>;

export const CoreLabel = ({
  color = 'darkGray',
  children,
  ...rest
}: CoreLabelProps): JSX.Element => {
  return (
    <Text tag="pre" className={style(colorMap[color])} {...rest}>
      {children}
    </Text>
  );
};
